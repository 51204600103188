.heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  h2 {
    font-size: 1.7rem;
    color: #cc6328;
    font-weight: 700;
    margin-bottom: 0;
  }

  h3.cohortInfo {
    font-size: 1rem;
    color: #333333;
    font-weight: 500;
    margin-bottom: 0;
  }

  p {
    font-family: "quicksand", sans-serif;
    font-size: 1.1rem;
  }

  .timer {
    transform: scale(0.7);
    position: absolute;
    display: flex;
    left: -12px;
  }
}

div.preSets {
  position: fixed;
  padding: 5%;
  top: 3%;
  right: 3%;
  bottom: 3%;
  left: 3%;
  border-radius: 9px;
  box-shadow: 5px 5px 42px #565656, -5px 5px 42px #565656, -5px -5px 42px #565656, 5px -5px 42px #565656;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  div {
    margin: 15px auto;
    padding: 21px 1%;
    width: 360px;
    max-width: 98%;
    background-color: #efefef;
    border-radius: 5px;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      img {
        width: 120px;
        height: 120px;
        max-width: 100%;
        object-fit: contain;
        margin: 5px auto;
        display: block;
      }

      p {
        padding: 5px 0px;
        margin: 7px 3%;
        text-align: center;
      }

      label {
        display: block;
        margin: 12px 3%;

        select {
          display: block;
          width: 100%;
          padding: 5px;
        }
      }

      button {
        padding: 5px;
        margin: 12px 3%;
        background-color: #cc6328;
        color: #ffffff;
        font-size: 1.1em;
        border: none;
        border-radius: 5px;
        display: block;
        width: 94%;
      }

      span {
        // display: block;
        margin: auto;
        padding: 5px;
        color: #ff4444;
      }

      div.preSetsWait {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ffc83b;
        margin: 0px auto;
        padding: 2% 0px;

        p {
          text-align: left;
        }

        img {
          width: 200px;
          height: 75px;
          max-width: 96%;
          object-fit: contain;
          margin: 0px auto;
          display: block;
        }
      }

    }
  }
}

.creditTable {
  margin-top: 40px;
  overflow-x: auto;
  width: 100%;
  border-radius: 15px;
  box-shadow: 8px 15px 30px #0000001a;
  padding: 24px 22px;
  background-color: #ffffff;

  .tableStyles {
    text-align: center;
    overflow-x: auto;
  }

  th {
    color: #606060;
    white-space: nowrap;
  }

  td {
    white-space: nowrap;
  }

  .welcomeAcc {
    margin-top: 25px;
    text-align: center;

    img {
      // display: none;
      max-width: 100%;
      object-fit: contain;
      max-height: 250px;
    }

    p {
      font-size: 1.1rem !important;
      font-family: "quicksand", sans-serif;
      text-align: left;
    }

    ol.steps,
    ul.dlinks {
      text-align: left;
    }

    ul.dlinks {
      li {
        a:before {
          content: "Download - ";
          display: inline;
        }
      }
    }
  }
}

.applyContainer {
  margin-top: 40px;

  .applyForm {
    // padding: 36px 40px;
    padding: 12px 12px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 8px 15px 30px #0000001a;

    .inputHint {
      font-size: 0.85rem;
      margin-bottom: 0;
      margin-top: 3px;

      span {
        font-weight: 600;
        color: #cc6328;
      }
    }
  }

  input.formInput,
  textarea.formInput,
  select.formInput {
    width: 100%;
    padding: 7px;
  }

  .joinedBullets {
    display: flex;
    // overflow-x: auto;
    justify-content: center;
    margin-top: 40px;
    list-style-type: none;
    padding-inline-start: 0;

    li {
      position: relative;
      text-align: center;
      margin: 0;
      margin-top: -4px;
      margin-right: 10px;
      padding-top: 0.07em;
      padding-bottom: 1.6em;
      font-size: 1.1rem;
      font-family: "quicksand", sans-serif;
      color: #212121;

      &:last-child {
        margin-right: 0;
      }

      &:last-child::before {
        height: 0px;
        margin-left: 0px;
      }

      &:before {
        background-color: #cc6328;
        height: 2px;
        width: calc(100% + 10px);
        content: "";
        position: absolute;
        top: -15px;
        bottom: 0px;
        left: 44px;
      }

      &::after {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23ffffff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        left: calc(50% - 15px);
        top: -30px;
        width: 30px;
        height: 30px;
      }

      &.active {
        color: #cc6328;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23cc6328' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
        }
      }

      b {
        font-weight: normal;

        &:first-of-type {
          display: none;
        }
      }
    }
  }
}

.applicationComplete {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 8px 15px 30px #0000001a;
  margin-top: 40px;
  text-align: center;

  h2 {
    font-weight: 700;
    color: #cc6328;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  p {
    font-size: 0.95rem;
    margin-bottom: 5px;
    font-family: "quicksand", sans-serif;
  }
}

.continueModal {
  padding: 15px;

  h3 {
    font-size: 1.3rem;
  }

  p {
    font-family: "quicksand", sans-serif;
    font-size: 1.15rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      font-size: 2rem;
      margin-bottom: 8px;
    }
  }

  .applyContainer {
    margin-top: 40px;

    .applyForm {
      padding: 36px 40px;
      border-radius: 15px;
      background-color: #fff;
      box-shadow: 8px 15px 30px #0000001a;

      .inputHint {
        font-size: 0.85rem;
        margin-bottom: 0;
        margin-top: 3px;

        span {
          font-weight: 600;
          color: #cc6328;
        }
      }
    }

    .joinedBullets {
      display: flex;
      // overflow-x: auto;
      margin-top: 40px;
      list-style-type: none;
      padding-inline-start: 0;

      li {
        position: relative;
        text-align: center;
        margin: 0;
        margin-top: -4px;
        margin-right: 10px;
        padding-top: 0.07em;
        padding-bottom: 1.6em;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        color: #212121;

        &:last-child {
          margin-right: 0;
        }

        &:last-child::before {
          height: 0px;
          margin-left: 0px;
        }

        &:before {
          background-color: #cc6328;
          height: 2px;
          width: calc(100% + 10px);
          content: "";
          position: absolute;
          top: -15px;
          bottom: 0px;
          left: 50px;
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23ffffff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
          left: calc(50% - 15px);
          top: -30px;
          width: 30px;
          height: 30px;
        }

        &.active {
          color: #cc6328;

          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23cc6328' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  .applicationComplete {
    width: 550px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    margin-top: 60px;
    text-align: center;

    h2 {
      font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }

    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }

  .continueModal {
    padding: 15px;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.15rem;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      font-size: 2rem;
      color: #cc6328;
      font-weight: 700;
    }

    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.1rem;
    }
  }

  .creditTable {
    margin-top: 40px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 8px 15px 30px #0000001a;
    padding: 24px 22px;
    background-color: #ffffff;

    .tableStyles {
      text-align: center;
    }

    th {
      color: #606060;
    }

    .welcomeAcc {
      margin-top: 25px;
      text-align: center;

      img {
        display: block;
        margin: 0 auto;
      }

      .mobileNoLoan {
        display: none;
      }

      p {
        font-size: 1.25rem;
        font-family: "quicksand", sans-serif;
      }
    }
  }

  .applyContainer {
    margin-top: 40px;

    .applyForm {
      padding: 36px 40px;
      border-radius: 15px;
      background-color: #fff;
      box-shadow: 8px 15px 30px #0000001a;

      .inputHint {
        font-size: 0.85rem;
        margin-bottom: 0;
        margin-top: 3px;

        span {
          font-weight: 600;
          color: #cc6328;
        }
      }
    }

    .joinedBullets {
      display: block;
      margin-top: 40px;
      list-style-type: none;
      padding-inline-start: 0;

      li {
        text-align: left;
        position: relative;
        margin: 0;
        margin-top: -4px;
        padding-top: 0.07em;
        padding-bottom: 1.6em;
        padding-left: 40px;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        color: #212121;

        &:last-child::before {
          height: 0px;
        }

        &:before {
          background-color: #cc6328;
          width: 2px;
          height: 100%;
          content: "";
          position: absolute;
          top: 25px;
          bottom: 0px;
          left: 14px;
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23ffffff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
          left: 0;
          top: 0px;
          width: 30px;
          height: 30px;
        }

        &.active {
          color: #cc6328;

          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23cc6328' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          }
        }

        b {
          font-weight: normal;

          &:first-of-type {
            display: initial;
          }
        }

        display: flex;

        b {
          font-weight: normal;

          &:last-of-type {
            display: none;
            order: -1;
            padding-right: 7px;
          }
        }
      }
    }
  }

  .applicationComplete {
    width: 550px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    margin-top: 60px;
    text-align: center;

    h2 {
      font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }

    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }

  .continueModal {
    padding: 15px;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.15rem;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}