.container {
    background-color: #F5F5F5;
    margin: 0 auto;
    text-align: center;
    padding: 0px 20px 50px;
    // padding-top: 40px;
    min-height: 100vh;
    img {
        width: 170px;
    }
    h1 {
        font-size: 1.75rem;
        font-weight: 700;
        // color: #cc6328;
        color: var(--main-theme-color);
    }
}

.loginBox {
    background-color: #FFFFFF;
    padding: 30px 35px 20px;
    border-radius: 15px;
    margin: 0 auto;
    width: 100%;
    margin-top: 50px;
    // margin-bottom: 50px;
    box-shadow: 8px 15px 30px #0000001A;
    .resetPassword {
        color: #cc6328;
        text-align: left;
        margin-top: 5px;
        cursor: pointer;
        a {
            color: #cc6328; 
            &:hover {
                text-decoration: none;
            }
        }
    }
    .authLink {
        font-family: 'quicksand', sans-serif;
        font-size: 1.05rem;
        a {
            font-weight: 700;
            color: #cc6328;
        }
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .container {
        padding: 0px 20px 50px;
        img {
            width: 200px;
        }
        h1 {
            font-size: 2.25rem;
        }
    }
    
    .loginBox {
        background-color: #FFFFFF;
        padding: 40px 60px 20px;
        border-radius: 15px;
        margin: 0 auto;
        width: 500px;
        margin-top: 50px;
        margin-bottom: 50px;
        box-shadow: 8px 15px 30px #0000001A;
        .resetPassword {
            color: #cc6328;
            text-align: left;
            margin-top: 5px;
            cursor: pointer;
            a {
                color: #cc6328; 
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .authLink {
            font-family: 'quicksand', sans-serif;
            font-size: 1.05rem;
            a {
                font-weight: 700;
                color: #cc6328;
            }
        }
    }    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}