.piechart {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
}

.pieChartContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 50%;
}

.scoresPieChart {
  width: 75%;
  height: 240px;
}

.legend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.buttoncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 85px 0;
}

label {
  font-weight: bold;
  margin-right: 20px;
}

.pieDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212153;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.pieDescription p {
  margin: 20px;
  margin-top: 40px;
}

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.selectContainer {
  margin: 0 25px;
}

.selectContainer label {
  color: #cc6328;
  font-weight: bold;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32"%3E%3Cpath d="M0 6h32v20H0z" fill="%23334" /%3E%3Cpath d="M10 16l6-6 6 6z" fill="#FFF" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 12px 8px;
  padding-right: 2rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  height: 38px;
  width: 100%;
  max-width: 100%;
}

select:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.formControl {
  display: block;
  width: 230px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button {
  background-color: #cc6328;
  color: #fff;
  border: none;
  border-radius: 5px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  padding: 0 30px;
}

.scoresLoading {
  margin: 40px auto;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  width: 550px;
  padding: 40px 60px;
  box-shadow: 8px 15px 30px #0000001a;
}

.scoresLoading h2 {
  color: #cc6328;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 25px;
}

.componentContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 788px) {
    flex-direction: column;
    justify-content: space-between;
  }
}

.piechart {
  width: 100%;
}

.componentContainer {
  align-items: center;
  padding: 0;
}

.cardContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.top {
  width: 100%;
}

.bottom {
  width: 100%;
}

@media (max-width: 1200px) {
  .legend {
    margin-top: 0px;
  }

  .buttoncontainer {
    width: 50%;
    margin: 85px 0;
  }

  label {
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px;
  }

  .cardContainer {
    flex-direction: column;
  }

  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 700px) {
  .piechart {
    flex-direction: column;
  }

  .pieChartContainer {
    flex-direction: column;
    min-width: 100%;
  }

  .scoresPieChart {
    width: 80%;
  }

  .legend {
    margin-top: 0px;
    width: 100%;
  }

  .buttoncontainer {
    margin: 80px 0 0 0;
  }

  .scoresLoading {
    width: 100%;
  }

  .scoresLoading h2 {
    font-size: 25px;
  }
}

@media (max-width: 500px) {
  .cardContainer {
    flex-direction: column;
  }

  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.noScoresMessage {
  margin: 40px auto;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  padding: 80px 60px;
  box-shadow: 8px 15px 30px #0000001a;
  width: 100%;

  img {
    display: block;
    margin: 0 auto;
  }

  .mobileNoScores {
    display: none;
  }

  p {
    font-size: 1.25rem;
    font-family: "quicksand", sans-serif;
  }
}

@media (max-width: 480px) {
  .noScoresMessage {
    img {
      display: none;
    }

    .mobileNoScores {
      display: block;
    }
  }

  .scoresLoading {
    padding: 20px;
  }
}

@media (max-width: 280px) {
  .scoresPieChart {
    height: 180px;
  }
}
