.rightPanel {
  width: 70%;
  // border: 3px solid #2d2833;
  // margin: 10px;
  // border-bottom-right-radius: 40px;

  // // box-shadow: 0 3px 3px 3px #00A5A2;
  // box-shadow: 0 3px 3px 3px #737373;
  // overflow: hidden;
  // height: 80vh;
}

.LMSContent {
  padding: 0 20px 20px;
  // border: 3px solid #2d2833;
  // margin: 10px;
  // border-bottom-right-radius: 40px;
  // box-shadow: 0 3px 3px 3px #737373;
}

.info {
  margin: 20px 0;
}

.info h2 {
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 0;
  color: #00a5a2;
}

.info p {
  font-size: 0.95rem;
  line-height: 170%;
}

.descriptionBox {
  margin-top: 40px;
}

.descriptionBoxHeadings {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e7eb;
}

.descriptionBoxHeadings div {
  margin-right: 20px;
  padding-bottom: 5px;
}

.descriptionBoxHeadings h3 {
  font-size: 1rem;
  cursor: pointer;
}

.video {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoWrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.noQuestions {
  margin: 30px auto;
}

audio {
  padding: 0;
  // height: 72px;
  // border: 4px solid #ffffff;
  background-color: #ffffff;
  width: 100%;
}

audio::-webkit-media-controls-panel {
  background-color: #ffffff;
  border-radius: 0;
  border: none;
}

audio::-webkit-media-controls-play-button {
  background-color: #00a5a2;
  border-radius: 50%;
  margin-right: 10px;
}

audio::-webkit-media-controls-current-time-display {
  color: #333;
}

audio::-webkit-media-controls-time-remaining-display {
  color: #333;
}

.audio {
  // background-color: #f4f6f9;
  border: none;
}

.vidEmbed {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.vidEmbed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
// .applicationComplete {
//   width: 100%;
//   margin: 0 auto;
//   background-color: #ffffff;
//   border-radius: 15px;
//   padding: 30px;
//   box-shadow: 8px 15px 30px #0000001a;
//   margin-top: 40px;
//   text-align: center;

//   h2 {
//     font-weight: 700;
//     color: #cc6328;
//     margin-top: 10px;
//     margin-bottom: 25px;
//   }

//   p {
//     font-size: 0.95rem;
//     margin-bottom: 5px;
//     font-family: "quicksand", sans-serif;
//   }

//   div.quizListHold {
//     display: flex;
//     flex-wrap: wrap;

//     div.quizList {
//       width: 100%;
//       display: flex;
//       border: 1px solid #00a5a2;
//       padding: 7px;
//       border-radius: 7px;
//       margin: 10px auto;
//       align-items: center;
//       transition: all 500ms;
//       position: relative;

//       &.quizListHide {
//         display: none;
//       }

//       &.quizListoptional {
//         border-style: dashed;
//       }

//       &:hover,
//       &.quizListActive {
//         transform: scale(1.05);
//         box-shadow: 10px 10px 10px #bcbcbc;
//       }

//       div.quizListInfo {
//         display: flex;
//         flex-direction: column;
//         flex-grow: 1;
//         margin: 0px 5px;

//         label {
//           display: flex;
//           flex-direction: column;
//           margin: 0px;
//           padding: 2px;

//           p {
//             margin-bottom: 10px;

//             g {
//               color: #cc6328;
//             }
//           }

//           textarea {
//             border-radius: 5px;
//             display: flex;
//             border: 2px solid #dddddd;
//             padding: 3px;
//             resize: none;

//             &:focus {
//               outline-color: #000000;
//               outline-style: double;
//             }
//           }
//         }

//         span {
//           border-bottom: 1px dashed #bcbcbc;
//         }
//       }

//       input {
//         display: none;
//       }
//     }

//     div.advisorList {
//       width: 100%;
//       display: flex;
//       border: 1px solid #00a5a2;
//       padding: 7px;
//       border-radius: 7px;
//       margin: 10px auto;
//       align-items: center;
//       transition: all 300ms;
//       cursor: pointer;
//       position: relative;

//       &:hover {
//         transform: scale(1.05);
//         box-shadow: 10px 10px 10px #bcbcbc;
//       }

//       .icon {
//         font-size: 3em;

//         &.iconChecked {
//           color: #00a5a2;
//         }

//         &.iconHidden {
//           display: none;
//         }

//         &.iconCheckedHover {
//           position: absolute;
//           top: 0;
//           right: 0;
//           bottom: 0;
//           left: 0;
//           margin: auto;
//           animation: bigger 500ms linear forwards;
//         }
//       }

//       .iconLoader {
//         position: absolute;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         left: 50%;
//         margin: auto;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//       }

//       div.advisorListInfo {
//         display: flex;
//         flex-direction: column;
//         flex-grow: 1;
//         margin: 0px 5px;

//         span {
//           border-bottom: 1px dashed #bcbcbc;
//         }
//       }

//       input {
//         display: none;
//       }
//     }
//   }

//   div.quizBtnLdr {
//     button.surveySubmitBtn {
//       border: 3px double #cc6328;
//       padding: 5px 15px;
//       border-radius: 5px;
//       background-color: #ffffff;
//       color: #00a5a2;
//       font-size: 1.4em;
//       font-weight: 700;
//       // display: none;
//       margin-top: 70px;

//       &:focus {
//         outline-color: #000000;
//         outline-style: double;
//       }
//     }

//     span.surveySubmitLoader {
//       display: none;
//     }
//   }

//   nav.playbookNav {
//     text-align: left;
//     margin: 7px auto;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;

//     Button {
//       margin: 5px !important;
//     }
//   }
// }

// .reading {
//   margin-bottom: 50px;
// }

// .reading p,
// ul li {
//   font-size: 0.95rem;
//   line-height: 170%;
// }

// .reading p {
//   // margin: 30px 0;
// }

// .reading .image {
//   width: 50%;
//   margin: 30px 0;
// }

// .image img {
//   width: 100%;
//   height: auto;
// }

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//   .applicationComplete {
//     width: 550px;
//     margin: 0 auto;
//     background-color: #ffffff;
//     border-radius: 15px;
//     padding: 40px 60px;
//     box-shadow: 8px 15px 30px #0000001a;
//     margin-top: 60px;
//     text-align: center;

//     h2 {
//       font-weight: 700;
//       color: #cc6328;
//       margin-top: 10px;
//       margin-bottom: 25px;
//     }

//     p {
//       font-size: 0.95rem;
//       margin-bottom: 5px;
//       font-family: "quicksand", sans-serif;
//     }
//   }
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//   .applicationComplete {
//     width: 550px;
//     margin: 0 auto;
//     background-color: #ffffff;
//     border-radius: 15px;
//     padding: 40px 60px;
//     box-shadow: 8px 15px 30px #0000001a;
//     margin-top: 60px;
//     text-align: center;

//     h2 {
//       font-weight: 700;
//       color: #cc6328;
//       margin-top: 10px;
//       margin-bottom: 25px;
//     }

//     p {
//       font-size: 0.95rem;
//       margin-bottom: 5px;
//       font-family: "quicksand", sans-serif;
//     }
//   }
// }

// @media (min-width: 992px) and (max-width: 1199px) {
// }

// @media (min-width: 768px) and (max-width: 991px) {
// }

// @media (min-width: 481px) and (max-width: 767px) {
// }

@media (max-width: 480px) {
  // .LMSContent {
  //   padding: 20px 10px;
  // }
}
