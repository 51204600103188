.heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  h2 {
    font-size: 1.7rem;
    color: #cc6328;
    font-weight: 700;
    margin-bottom: 0;
  }
  p {
    font-family: "quicksand", sans-serif;
    font-size: 1.1rem;
  }
}

.creditTable {
  margin-top: 40px;
  overflow-x: auto;
  width: 100%;
  border-radius: 15px;
  box-shadow: 8px 15px 30px #0000001a;
  padding: 24px 22px;
  background-color: #ffffff;
  .tableStyles {
    text-align: center;
    overflow-x: auto;
  }
  th {
    color: #606060;
    white-space: nowrap;
  }
  td {
    white-space: nowrap;
  }
  .noLoanMessage {
    margin-top: 25px;
    text-align: center;
    img {
      display: none;
    }
    p {
      font-size: 1.25rem;
      font-family: "quicksand", sans-serif;
    }
  }
}

.applyContainer {
  margin-top: 40px;
  .applyForm {
    padding: 36px 40px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 8px 15px 30px #0000001a;
    .inputHint {
      font-size: 0.85rem;
      margin-bottom: 0;
      margin-top: 3px;
      span {
        font-weight: 600;
        color: #cc6328;
      }
    }
  }
  .joinedBullets {
    display: flex;
    // overflow-x: auto;
    justify-content: center;
    margin-top: 40px;
    list-style-type: none;
    padding-inline-start: 0;
    li {
      position: relative;
      text-align: center;
      margin: 0;
      margin-top: -4px;
      margin-right: 10px;
      padding-top: 0.07em;
      padding-bottom: 1.6em;
      font-size: 1.1rem;
      font-family: "quicksand", sans-serif;
      color: #212121;
      &:last-child {
        margin-right: 0;
      }
      &:last-child::before {
        height: 0px;
        margin-left: 0px;
      }
      &:before {
        background-color: #cc6328;
        height: 2px;
        width: calc(100% + 10px);
        content: "";
        position: absolute;
        top: -15px;
        bottom: 0px;
        left: 44px;
      }
      &::after {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23ffffff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        left: calc(50% - 15px);
        top: -30px;
        width: 30px;
        height: 30px;
      }
      &.active {
        color: #cc6328;
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23741763' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
        }
      }
    }
  }
}

.applicationComplete {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 8px 15px 30px #0000001a;
  margin-top: 40px;
  text-align: center;
  h2 {
    font-weight: 700;
    color: #cc6328;
    margin-top: 10px;
    margin-bottom: 25px;
  }
  p {
    font-size: 0.95rem;
    margin-bottom: 5px;
    font-family: "quicksand", sans-serif;
  }
}

.continueModal {
  padding: 15px;
  h3 {
    font-size: 1.3rem;
  }
  p {
    font-family: "quicksand", sans-serif;
    font-size: 1.15rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    h2 {
      font-size: 2rem;
      margin-bottom: 8px;
    }
  }

  .applyContainer {
    margin-top: 40px;
    .applyForm {
      padding: 36px 40px;
      border-radius: 15px;
      background-color: #fff;
      box-shadow: 8px 15px 30px #0000001a;
      .inputHint {
        font-size: 0.85rem;
        margin-bottom: 0;
        margin-top: 3px;
        span {
          font-weight: 600;
          color: #cc6328;
        }
      }
    }
    .joinedBullets {
      display: flex;
      // overflow-x: auto;
      margin-top: 40px;
      list-style-type: none;
      padding-inline-start: 0;
      li {
        position: relative;
        text-align: center;
        margin: 0;
        margin-top: -4px;
        margin-right: 10px;
        padding-top: 0.07em;
        padding-bottom: 1.6em;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        color: #212121;
        &:last-child {
          margin-right: 0;
        }
        &:last-child::before {
          height: 0px;
          margin-left: 0px;
        }
        &:before {
          background-color: #cc6328;
          height: 2px;
          width: calc(100% + 10px);
          content: "";
          position: absolute;
          top: -15px;
          bottom: 0px;
          left: 50px;
        }
        &::after {
          content: "";
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23ffffff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
          left: calc(50% - 15px);
          top: -30px;
          width: 30px;
          height: 30px;
        }
        &.active {
          color: #cc6328;
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23741763' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  .applicationComplete {
    width: 550px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    margin-top: 60px;
    text-align: center;
    h2 {
      font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }
    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }

  .continueModal {
    padding: 15px;
    h3 {
      font-size: 1.3rem;
    }
    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.15rem;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    h2 {
      font-size: 2rem;
      color: #cc6328;
      font-weight: 700;
    }
    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.1rem;
    }
  }

  .creditTable {
    margin-top: 40px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 8px 15px 30px #0000001a;
    padding: 24px 22px;
    background-color: #ffffff;
    .tableStyles {
      text-align: center;
    }
    th {
      color: #606060;
    }
    .noLoanMessage {
      margin-top: 25px;
      text-align: center;
      img {
        display: block;
        margin: 0 auto;
      }
      .mobileNoLoan {
        display: none;
      }
      p {
        font-size: 1.25rem;
        font-family: "quicksand", sans-serif;
      }
    }
  }

  .applyContainer {
    margin-top: 40px;
    .applyForm {
      padding: 36px 40px;
      border-radius: 15px;
      background-color: #fff;
      box-shadow: 8px 15px 30px #0000001a;
      .inputHint {
        font-size: 0.85rem;
        margin-bottom: 0;
        margin-top: 3px;
        span {
          font-weight: 600;
          color: #cc6328;
        }
      }
    }
    .joinedBullets {
      display: block;
      margin-top: 40px;
      list-style-type: none;
      padding-inline-start: 0;
      li {
        text-align: left;
        position: relative;
        margin: 0;
        margin-top: -4px;
        padding-top: 0.07em;
        padding-bottom: 1.6em;
        padding-left: 40px;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        color: #212121;
        &:last-child::before {
          height: 0px;
        }
        &:before {
          background-color: #cc6328;
          width: 2px;
          height: 100%;
          content: "";
          position: absolute;
          top: 25px;
          bottom: 0px;
          left: 14px;
        }
        &::after {
          content: "";
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23ffffff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
          left: 0;
          top: 0px;
          width: 30px;
          height: 30px;
        }
        &.active {
          color: #cc6328;
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23741763' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  .applicationComplete {
    width: 550px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    margin-top: 60px;
    text-align: center;
    h2 {
      font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }
    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }

  .continueModal {
    padding: 15px;
    h3 {
      font-size: 1.3rem;
    }
    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.15rem;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
