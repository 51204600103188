// .resourcesCont {
//   width: 80%;
// }

.topBar {
  padding: 30px 20px 20px 0;
  border-bottom: 1px solid #e2e7eb;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
}

.openMenu {
  cursor: pointer;
  border: 1px solid #cc6328;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 10px;
  position: relative;
  left: -10px;
  display: flex;
  align-items: center;
}

.openMenu:hover {
  left: -2px;
}

.icon {
  width: 60px;
  height: 60px;
}

.header h2 {
  color: #cc6328;
  margin-left: 5px;
  margin-bottom: 0;
}

.contentBar {
  display: flex;
  padding: 20px;
}

.reading {
  margin-bottom: 50px;
}

.reading p,
ul li {
  font-size: 0.95rem;
  line-height: 170%;
}

.reading p {
  // margin: 30px 0;
}

.reading .image {
  width: 50%;
  margin: 30px 0;
}

.image img {
  width: 100%;
  height: auto;
}
