.courseInfo {
  width: 30%;
  // padding: 30px 20px;
  // height: 80vh;
  // overflow: hidden;
  // padding-bottom: 20px;
}

.mentorCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mentorCont h5,
.upcoming h5 {
  font-size: 16px;
  font-weight: bold;
}

.upcomingHeading {
  padding: 40px 20px 20px;
}

.icon {
  margin: 15px;
}

.mentorCont p {
  font-size: 15px;
  margin: 0;
}

.upcoming {
  padding: 0 0 20px;
}

.classes {
  // overflow: auto;
  // height: 70vh;
  padding: 0 20px 20px;
}

.classes::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.classes::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
  visibility: hidden;
}

.classes:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.class {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  border-left: 10px solid #00a5a2;
  margin: 20px 0;
}

.topic p {
  font-size: 14px;
  margin-right: 20px;
}

.detailedTime {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 5px 0;
}

.detailedTime p {
  margin-bottom: 0;
  margin-left: 5px;
}

.openMeeting {
  transform: rotate(45deg);
  float: right;
  cursor: pointer;
}
