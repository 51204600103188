.contentNotes {
  padding: 40px 0 20px;
}

.searchNotes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 30px 10px;
}
.searchContainer {
  border-bottom: 1px solid #e2e7eb;
  width: 230px;
  cursor: pointer;
}

.searchContainer input {
  margin-right: 10px;
  width: 200px;
  outline: none;
  border: none;
}

.notesSection {
  display: flex;
  align-items: center;
}

.listNotes {
  max-height: 400px;
  // height: calc(80vh - 132px);
  overflow-y: auto;
  padding-left: 10px;
}

.listNotes::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.listNotes::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
}

.notesForm {
  width: 65%;
  height: 100%;
}

.formBody {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #00a5a2;
  margin: 0 20px;
  border-radius: 5px;
  margin-top: 10px;
  height: calc(400px - 80px);
}

.formBody input,
textarea {
  outline: none;
  border: none;
}

.formBody input {
  margin-bottom: 10px;
  font-weight: bold;
}

.formBody textarea::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.formBody textarea::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
}

.button {
  float: right;
  margin-right: 20px;
}

.note {
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid #00a5a2;
  border-radius: 5px;
  flex: 1 1 300px;

  width: 230px;
  padding: 10px;
  margin: 10px 20px 10px 0;
  // width: 230px;
  // height: 121px;
  transition: all 300ms;
  cursor: pointer !important;

  &:hover {
    transform: scale(1.05);
    box-shadow: 10px 10px 10px #bcbcbc;
  }
}

.note h5 {
  color: #00a5a2;
  font-weight: bold;
  font-size: 1rem;
}

.noteCont {
  max-height: 121px;
  overflow: hidden;
}

.note p {
  font-size: 0.8rem;
  line-height: 170%;
  color: #000000;
  margin: 0;
}

.newNote {
  width: 100%;
  height: 80px;
}

// .list {
//   display: flex;
//   align-items: flex-start;
// }
