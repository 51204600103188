.personalInfo {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  margin-top: 60px;
  margin-bottom: 70px;
  padding: 30px;
  box-shadow: 8px 15px 30px #0000001a;
  h3 {
    text-align: left;
    font-size: 1.65rem;
    margin-bottom: 25px;
    font-weight: 600;
    font-family: "quicksand", sans-serif;
  }
  .residentialInfo,
  .kinInfo {
    margin-top: 60px;
  }
  .bankInfo {
    margin-top: 60px;
    h3 {
      margin-bottom: 0;
    }
    p {
      font-size: 0.8rem;
      text-align: left;
      font-weight: 600;
      color: #cc6328;
      margin-bottom: 25px;
      font-family: "quicksand", sans-serif;
    }
  }
}

.loadingStyle {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderWrapper {
  margin-top: 40px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .personalInfo {
    width: 100%;
    padding: 40px;
    h3 {
      font-size: 1.75rem;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .personalInfo {
    width: 650px;
    padding: 40px 60px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
