/* ScoresGaugeChart.module.scss */

.gauge-chart-container {
    position: relative;
}

.gauge-value {
    position: absolute;
    top: 15px;
    /* Adjust this value to position the value above the outer radius */
    left: calc(50% - 30px);
    /* Adjust this value to center the value element */
    width: 30px;
    /* Adjust the width as needed */
    text-align: center;
    font-size: 16px;
    /* Adjust the font size as needed */
    font-weight: bold;
    color: #00A5A2;
}