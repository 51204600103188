:root {
  --fontColorOne: #212121;
  --fontFamilyOne: "quicksand";
  --fontFamilyTwo: "Kanit";
  --backgroundOne: " #F5F5F5";
}

.mainSection {
  background-color: #f5f5f5;
  padding: 60px 25px;
  margin-top: -100px;
  .container {
    margin-top: 90px;
    h3 {
      font-family: var(--fontFamilyTwo);
      font-weight: 700;
      color: #cc6328;
      text-align: center;
      margin: 0 auto;
      font-size: 1.9rem;
      margin-bottom: 40px;
      width: 90%;
    }
    p {
      font-family: var(--fontFamilyOne);
      font-weight: 600;
      text-align: center;
      font-size: 1.3rem;
      margin-bottom: 40px;
    }
    .calculatorBox {
      width: 100%;
      padding: 45px 30px;
      box-shadow: 8px 15px 30px #0000001a;
      margin: 0 auto;
      border-radius: 15px;
    }
  }
}

.limitError {
  font-family: var(--fontFamilyOne);
  font-size: 0.85rem !important;
  margin-left: 1.2rem;
  color: red;
  text-align: left !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .mainSection {
    padding: 70px 30px;
    .container {
      h3 {
        font-size: 2.2rem;
        margin-bottom: 50px;
      }
      p {
        font-size: 1.4rem;
        margin-bottom: 40px;
      }
      .calculatorBox {
        width: 550px;
        padding: 55px 60px;
      }
    }
  }

  .limitError {
    font-size: 0.85rem !important;
    margin-left: 1.2rem;
    color: red;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .mainSection {
    padding: 70px 100px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
