.container {
  background-color: #f5f5f5;
  margin: 0 auto;
  text-align: center;
  padding: 0px 25px;
  min-height: 100vh;
  h1 {
    font-size: 32px;
    margin-top: 20px;
    font-weight: 700;
    color: #cc6328;
  }
  p {
    font-family: "quicksand", sans-serif;
    font-size: 1.1rem;
  }
  img{
    max-width: 200px;
  }
}

.verifyBox {
  background-color: #ffffff;
  padding: 40px 30px 20px;
  border-radius: 15px;
  margin: 0 auto;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 8px 15px 30px #0000001a;
  .authLink {
    font-family: "quicksand", sans-serif;
    font-size: 1.05rem;
    a {
      font-weight: 700;
      color: #cc6328;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    h1 {
      font-size: 36px;
    }
  }

  .verifyBox {
    padding: 40px 60px 20px;
    width: 500px;
    .authLink {
      font-family: "quicksand", sans-serif;
      font-size: 1.05rem;
      a {
        font-weight: 700;
        color: #cc6328;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
