.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 70px 0 30px;
}

@media print {
    .noPrint {
        display: none;
    }
}

@media (max-width: 480px) {
    .buttonsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 70px 0 30px;
    }
}