.heading {
  margin: 30px 0 20px;
}

.heading h3 {
  font-size: 1.2rem;
  font-weight: bold;
  font-size: 1.2rem;
}

.reviewContainer {
  width: fit-content;
}

.stars {
  display: flex;
  align-items: center;
  width: fit-content;
}

.star {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.reviewContainer textarea {
  margin: 15px 0;
  padding: 5px;
  outline: auto;
  outline-color: #e4e4e4;
  width: 100%;
  min-height: 100px;
  font-size: 0.9rem;
}

.noQuestions {
  padding: 20px 0;
}

.noQuestions {
  img {
    display: block;
    margin: 20px 0;
  }

  .mobileNoQuestions {
    display: none;
  }

  p {
    font-size: 1.25rem;
    font-family: "quicksand", sans-serif;
  }
}

.errorQuestions {
  margin: 20px 0;
}

.reviewCmment {
  margin: 20px 0;
}

@media (max-width: 480px) {
  .question p {
    font-size: 0.8rem;
  }

  .noQuestions {
    img {
      display: none;
    }

    .mobileNoQuestions {
      display: block;
    }
  }
}

@media (max-width: 380px) {
  .star {
    width: 50px;
    height: 50px;
  }
}
