.assignmentsCont {
  // width: 80%;
}

.topBar {
  padding: 30px 20px 20px 0;
  border-bottom: 1px solid #e2e7eb;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
}

.openMenu {
  cursor: pointer;
  border: 1px solid #cc6328;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  // padding: 5px;
  padding-left: 10px;
  position: relative;
  left: -10px;
  display: flex;
  align-items: center;
  // justify-content: center;
}

.openMenu:hover {
  left: -2px;
}

.icon {
  width: 60px;
  height: 60px;
}

.header h2 {
  color: #cc6328;
  margin-left: 5px;
  margin-bottom: 0;
}

.search {
  width: 100%;
  height: 100px;
}

.searchContainer {
  float: right;
  margin: 20px;
}

.searchContainer label {
  color: #212153;
  font-weight: bold;
  font-size: 0.8125rem;
  margin-bottom: 10px;
}

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32"%3E%3Cpath d="M0 6h32v20H0z" fill="%23334" /%3E%3Cpath d="M10 16l6-6 6 6z" fill="#FFF" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 12px 8px;
  padding-right: 2rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  height: 38px;
  width: 250px;
  // max-width: 100%;
  // z-index: 1;
}

.select:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(255, 200, 59, 0.5);
}

.formControl {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// .searchContainer input {
//   margin-right: 10px;
//   width: 230px;
//   outline: none;
//   border: none;
// }

.assignments {
  width: 80%;
  margin: 0 auto;
}

.applicationComplete {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  // padding: 20px;
  // box-shadow: 8px 15px 30px #0000001a;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  h2 {
    font-weight: 700;
    color: #cc6328;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  p {
    font-size: 0.95rem;
    margin-bottom: 5px;
    font-family: "quicksand", sans-serif;
  }

  div.advisorsListHold {
    display: flex;
    flex-wrap: wrap;

    div.advisorList {
      width: 100%;
      display: flex;
      border: 1px solid #00a5a2;
      padding: 7px;
      border-radius: 7px;
      margin: 10px auto;
      align-items: center;
      transition: all 300ms;
      cursor: pointer;
      position: relative;

      // &:hover {
      //   transform: scale(1.05);
      //   box-shadow: 10px 10px 10px #bcbcbc;
      // }

      .icon {
        font-size: 1em;
        margin-right: 10px;

        &.iconChecked {
          color: #00a5a2;
        }

        &.iconHidden {
          display: none;
        }

        &.iconCheckedHover {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          animation: bigger 500ms linear forwards;
        }
      }

      .iconLoader {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      div.advisorListInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0px 5px;

        span {
          border-bottom: 1px dashed #bcbcbc;
        }
      }
    }
  }

  nav.playbookNav {
    text-align: left;
    margin: 7px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    Button {
      margin: 5px !important;
    }
  }
}

.titleCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  max-width: 110px;
  border: none;
  border-radius: 5px;
  margin: 10px 0 0;
  font-size: 13px;
}

.loader {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px auto;
}

.noAssignmentCont {
  margin: 20px auto;
}

.errorQuestions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px auto;
}
