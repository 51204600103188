.topBar {
  padding: 30px 20px 20px 0;
  border-bottom: 1px solid #e2e7eb;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
}

.openMenu {
  cursor: pointer;
  border: 1px solid #cc6328;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  // padding: 5px;
  padding-left: 10px;
  position: relative;
  left: -10px;
  display: flex;
  align-items: center;
  // justify-content: center;
}

.openMenu:hover {
  left: -2px;
}

.icon {
  width: 60px;
  height: 60px;
}

.header h2 {
  color: #cc6328;
  margin-left: 10px;
  margin-bottom: 0;
}

.contentBar {
  overflow: hidden;
}

.tableBody {
  width: 95%;
  background-color: #ffffff;
  margin: 1.6rem auto;
  margin-top: 0.8rem;
  border-radius: 0.6rem;
  overflow: auto;
}

table {
  width: 100%;
}

thead,
th {
  position: sticky;
  top: 0;
  color: #212153;
  //left: 0;
  background-color: #fff;
}

tbody tr {
  border-bottom: 1px solid #e2e7eb;
}

.avgScore {
  margin: 20px 20px 30px 0;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avgScore h4 {
  margin-right: 10px;
  font-size: 18px;
}

.button {
  background-color: #00a5a2;
  border-radius: 3px;
  padding: 3px;
  cursor: pointer;
  color: #ffffff;
}

.icons {
  display: flex;
  align-items: center;
}