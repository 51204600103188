.navTab {
    margin-top: 20px;
    ul {
        display: flex;
        list-style-type: none;
        padding-inline-start: 0;
        li {
            cursor: pointer;
            margin-right: 30px;
            font-family: var(--fontFamilyOne);
            font-size: 1.2rem;
            font-weight: 700;
            &.active {
                border-bottom: 4px solid #cc6328;
            }
        }
    }
}