.button {
    font-family: 'quicksand', sans-serif;
    font-size: 1.15rem;
    font-weight: 700;
    border-radius: 4px;
    border: none;
    padding: 8px 18px;
    margin: 3px 10px;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: rgba(116, 23, 99, 0.9);
    }

    &:disabled,
    &[disabled] {
        opacity: 0.7;
    }
}


@media print {
    .button {
        display: none;
    }

}

@media (min-width: 910px) and (max-width: 1280px) {
    .button {
        padding: 8px 10px;
        margin: 3px 3px;
    }
}

// @media (min-width: 992px) {
//     .button {
//         margin: 0 10px;
//     }
// }

@media (max-width: 768px) {
    .button {
        padding: 8px 10px;
        margin: 3px 3px;
    }
}