.heroSection {
  padding: 0px 10px 0;
  min-height: 100vh;
  background-color: #f5f5f5;
  margin-top: -100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    margin: 0 auto;
    margin-top: 90px;
    max-width: 1200px;
  }

  .mainCopy {
    .copyGroup {
      position: relative;

      .pattern {
        position: absolute;
        top: -10%;
        left: 2%;
        width: 100px;
        z-index: 1;
      }
    }

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 2.8rem;
      font-weight: 400;
      width: 100%;
      text-align: center;
      color: #cc6328;
      z-index: 3;
      position: relative;
    }

    h3 {
      width: 100%;
      font-size: 1rem;
      margin-top: 20px;
      text-align: center;
      font-weight: 600;
      font-family: "quicksand", sans-serif;
      color: #2f2f2f;
    }

    input {
      border-radius: 35px;
      padding: 10px 10px;
      width: 90%;
      margin: 0 auto;
      text-align: center;
      border: 1px solid #cc6328;
      margin-top: 35px;
      font-family: "quicksand", sans-serif;
      display: block;
      font-size: 1.15rem;

      &:focus {
        outline: none;
      }
    }

    button {
      font-family: "quicksand", sans-serif;
      width: 90%;
      display: block;
      border-radius: 35px;
      margin: 0 auto;
      padding: 10px 10px;
      background-color: #cc6328;
      color: #fff;
      border: 1px solid #cc6328;
      margin-top: 15px;
      font-size: 1.15rem;
      font-weight: 600;

      &:focus {
        outline: none;
      }
    }
  }

  .imageGroup {
    display: none;
    justify-content: center;
    align-items: center;

    .imgContainer {
      position: relative;

      .mainImg {
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        position: relative;
        width: 350px;
        z-index: 2;
      }

      .minorImg {
        position: absolute;
        top: -70px;
        width: 140px;
        right: -70px;
        border-radius: 25px;
        z-index: 3;
      }

      .minorImg2 {
        position: absolute;
        bottom: -70px;
        width: 140px;
        left: -70px;
        border-radius: 25px;
        z-index: 3;
      }

      .firstCircle {
        position: absolute;
        top: 0px;
        width: 140px;
        right: -70px;
        z-index: 1;
      }

      .secondCircle {
        position: absolute;
        bottom: 0px;
        width: 140px;
        left: -70px;
      }
    }
  }
}

.contactSection {
  padding: 40px 30px 30px;
  background-color: #f5f5f5;

  .container {
    max-width: 900px;
    margin: 0 auto;

    h2 {
      text-align: center;
      font-weight: 600;
      color: #cc6328;
      font-size: 1.9rem;
      margin-bottom: 60px;
    }

    .contactContainer {
      background-color: #fff;
      position: relative;
      z-index: 5;
      box-shadow: 15px 20px 35px #00000017;
      border-radius: 15px;

      .addressDetails,
      .contactForm {
        padding: 30px 30px;
      }

      .addressCol {
        border-right: none;
      }

      h3 {
        font-size: 1.65rem;
        color: #cc6328;
        font-family: "quicksand", sans-serif;
        font-weight: 700;
        margin-bottom: 30px;
      }

      .contactGroup {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .icon {
          display: block;
          margin-right: 15px;
        }

        p {
          margin-bottom: 0;
          font-size: 1.1rem;
          font-family: "quicksand", sans-serif;
        }
      }

      .inputWrapper {
        margin-bottom: 15px;
      }

      button {
        display: block;
        margin-top: 15px;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        font-weight: 600;
        width: 100%;
        padding: 10px 60px;
        border-radius: 12px;
        border: 2px solid #cc6328;
        background-color: rgba(0, 0, 0, 0);
        color: #cc6328;
        transition: 0.5s;

        &:hover {
          background-color: #cc6328;
          color: #fff;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.subFooter {
  padding: 80px 100px;
  background-color: #fec949;
  // background: linear-gradient(to left, #fec949, #1e2144, #00a5a2);
  background: #f5f5f5;
  margin-top: -250px;
  position: relative;
  z-index: 2;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .heroSection {
    padding: 0px 40px 0;

    .mainCopy {
      .copyGroup {
        position: relative;

        .pattern {
          position: absolute;
          top: -10px;
          left: -2%;
          width: 120px;
          z-index: 1;
        }
      }

      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 3.2rem;
      }

      h3 {
        font-size: 1.5rem;
        width: 85%;
        margin: 0 auto;
        margin-top: 30px;
      }

      input {
        width: 90%;
        margin-top: 45px;
        font-size: 1.2rem;
      }

      button {
        width: 90%;
        font-size: 1.2rem;
      }
    }

    .imageGroup {
      display: none;
    }
  }

  .featureSection {
    .featureBox {
      .featureIcon {
        font-size: 3.6rem;
      }

      h2 {
        font-size: 1.55rem;
      }

      p {
        font-size: 1.3rem;
      }
    }
  }

  .aboutSection {
    h5 {
      font-size: 1.2rem;
    }

    h2 {
      font-size: 1.85rem;
    }

    p {
      font-size: 1.2rem;
      font-family: "quicksand", sans-serif;
    }

    .founderQuote {
      font-size: 1.4rem;
    }

    .quoter {
      font-size: 1.2rem;

      span {
        font-weight: 600;
      }
    }

    .imageGroup {
      display: flex;
      justify-content: center;
      align-items: center;

      .imageContainer {
        .rect {
          width: 400px;
          height: 400px;
        }
      }
    }
  }

  .productSection {
    padding: 40px 30px;

    .container {
      margin: 0 auto;
      max-width: 1200px;

      h5 {
        font-size: 1.2rem;
      }

      h2 {
        font-size: 1.85rem;
      }

      .productBox {
        height: 100%;

        h3 {
          font-size: 1.6rem;
        }

        p {
          font-size: 1.15rem;
        }
      }
    }
  }

  .repeatSection {
    padding: 40px 30px;
    background-color: #f5f5f5;

    .container {
      .styledBox {
        padding: 35px 35px;

        .circlePatternOne {
          position: absolute;
          left: -200px;
          bottom: -100%;
        }

        .circlePatternTwo {
          position: absolute;
          right: -200px;
          top: -100%;
          z-index: 1;
        }

        .access {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        h2 {
          font-size: 1.9rem;
          width: 85%;
        }

        input {
          width: 85%;
        }

        button {
          width: 85%;
        }
      }
    }
  }

  .contactSection {
    padding: 40px 30px 80px;
    background-color: #f5f5f5;

    .container {
      max-width: 900px;
      margin: 0 auto;

      h2 {
        text-align: center;
        font-weight: 600;
        color: #cc6328;
        font-size: 1.9rem;
        margin-bottom: 60px;
      }

      .contactContainer {
        background-color: #fff;
        position: relative;
        z-index: 5;
        box-shadow: 15px 20px 35px #00000017;
        border-radius: 15px;

        .addressDetails,
        .contactForm {
          padding: 40px 50px;
        }

        .addressCol {
          border-right: 1px solid #cc6328;
        }

        h3 {
          font-size: 1.65rem;
          color: #cc6328;
          font-family: "quicksand", sans-serif;
          font-weight: 700;
          margin-bottom: 30px;
        }

        .contactGroup {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .icon {
            display: block;
            margin-right: 15px;
          }

          p {
            margin-bottom: 0;
            font-size: 1.1rem;
            font-family: "quicksand", sans-serif;
          }
        }

        .inputWrapper {
          margin-bottom: 15px;
        }

        button {
          display: block;
          margin-top: 15px;
          font-size: 1.1rem;
          font-family: "quicksand", sans-serif;
          font-weight: 600;
          padding: 10px 60px;
          border-radius: 12px;
          border: 2px solid #cc6328;
          background-color: rgba(0, 0, 0, 0);
          color: #cc6328;
          transition: 0.5s;

          &:hover {
            background-color: #cc6328;
            color: #fff;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .heroSection {
    padding: 0px 40px 0;

    .mainCopy {
      .copyGroup {
        position: relative;

        .pattern {
          position: absolute;
          top: -50px;
          left: -30px;
          width: 120px;
          z-index: 1;
        }
      }

      text-align: left;

      h1 {
        font-size: 2.8rem;
        text-align: left;
        width: 95%;
      }

      h3 {
        width: 80%;
        font-size: 1.4rem;
        text-align: left;
        margin: 0;
      }

      input {
        width: 300px;
        margin: 0;
        margin-top: 25px;
        font-size: 1.15rem;
      }

      button {
        width: 300px;
        margin: 0;
        margin-top: 15px;
        font-size: 1.15rem;
      }
    }

    .imageGroup {
      display: flex;
      justify-content: center;
      align-items: center;

      .imgContainer {
        position: relative;

        .mainImg {
          width: 300px;
          z-index: 2;
        }

        .minorImg {
          top: -50px;
          width: 100px;
          right: -50px;
          z-index: 3;
        }

        .minorImg2 {
          bottom: -50px;
          width: 100px;
          left: -50px;
          z-index: 3;
        }

        .firstCircle {
          top: 0px;
          width: 100px;
          right: -50px;
        }

        .secondCircle {
          bottom: 0px;
          width: 100px;
          left: -50px;
        }
      }
    }
  }

  .featureSection {
    .featureBox {
      padding: 30px 15px;

      .featureIcon {
        font-size: 3.6rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      p {
        margin-bottom: 0;
        // width: 50%;
        margin: auto;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        font-weight: 600;
      }
    }
  }

  .aboutSection {
    padding: 50px 50px;
    background-color: #f4e7f2;
    text-align: left;

    .container {
      h5 {
        font-size: 1.1rem;
      }

      h2 {
        font-size: 1.65rem;
        margin-bottom: 25px;
      }

      p {
        font-size: 1.2rem;
      }

      .imageGroup {
        .imageContainer {
          position: relative;

          img {
            position: relative;
            z-index: 3;
            width: 320px;
          }

          .rect {
            background-color: #841fe8;
            width: 350px;
            height: 350px;
            border-radius: 35px;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }

  .productSection {
    padding: 50px 50px;

    .container {
      h5 {
        text-align: center;
        font-size: 1.1rem;
      }

      h2 {
        text-align: center;
        font-size: 1.65rem;
        width: 50%;
      }

      .productBox {
        padding: 25px 35px;
        height: 360px;

        h3 {
          font-family: "somoLight", sans-serif;
          font-weight: 600;
          font-size: 1.6rem;
          margin-top: 30px;
          margin-bottom: 18px;
        }

        p {
          font-size: 1.2rem;
        }
      }
    }
  }

  .repeatSection {
    padding: 50px 50px;

    .container {
      .styledBox {
        background-color: #cc6328;
        border-radius: 25px;
        padding: 80px 100px;
        position: relative;

        .circlePatternOne {
          position: absolute;
          left: -180px;
          bottom: -80%;
        }

        .circlePatternTwo {
          position: absolute;
          right: -200px;
          top: -100%;
          z-index: 1;
        }

        .circle {
          border-radius: 100%;
          position: absolute;
        }

        .firstCircle {
          width: 180px;
          height: 180px;
          background-color: #f5f5f534;
          top: -90px;
          left: -90px;
        }

        .secondCircle {
          width: 70px;
          height: 70px;
          background-color: #f5f5f534;
          top: 22.5px;
          left: 45px;
        }

        .thirdCircle {
          width: 180px;
          height: 180px;
          background-color: #f5f5f534;
          left: 29%;
          bottom: -100px;
        }

        .fourthCircle {
          width: 44px;
          height: 44px;
          background-color: #f7ceef9a;
          left: 32%;
          bottom: 56px;
        }

        .fifthCircle {
          width: 70px;
          height: 70px;
          background-color: #f49ee453;
          left: 43%;
          top: 22.5px;
        }

        .sixthCircle {
          width: 44px;
          height: 44px;
          background-color: #f7ceef9a;
          left: calc(43% + 50px);
          top: calc(70px - 22.5px);
        }

        .seventhCircle {
          width: 180px;
          height: 180px;
          background-color: #f5f5f534;
          right: 0;
          bottom: 35px;
        }

        .eigthCircle {
          width: 70px;
          height: 70px;
          background-color: #f49ee453;
          right: 20px;
          bottom: 15px;
        }

        .ninthCircle {
          width: 44px;
          height: 44px;
          background-color: #f7ceef9a;
          right: 0;
          bottom: 50px;
        }

        .access {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        h2 {
          font-size: 2.1rem;
          color: #fff;
          font-weight: 600;
          width: 90%;
          margin-bottom: 0;
          text-align: center;
        }

        input {
          margin-top: 40px;
          width: 85%;
          font-family: "quicksand", sans-serif;
          font-size: 1.1rem;
          padding: 14px 25px;
        }

        button {
          background-color: #f5f5f5;
          border: 1px solid #f5f5f5;
          border-radius: 35px;
          display: block;
          margin: 0 auto;
          margin-top: 20px;
          padding: 14px 25px;
          width: 85%;
        }
      }
    }
  }

  .contactSection {
    padding: 40px 100px 80px;
    background-color: #f5f5f5;

    .container {
      max-width: 900px;
      margin: 0 auto;

      h2 {
        text-align: center;
        font-weight: 600;
        color: #cc6328;
        font-size: 1.9rem;
        margin-bottom: 60px;
      }

      .contactContainer {
        background-color: #fff;
        position: relative;
        z-index: 5;
        box-shadow: 15px 20px 35px #00000017;
        border-radius: 15px;

        .addressDetails,
        .contactForm {
          padding: 40px 50px;
        }

        .addressCol {
          border-right: 1px solid #cc6328;
        }

        h3 {
          font-size: 1.65rem;
          color: #cc6328;
          font-family: "quicksand", sans-serif;
          font-weight: 700;
          margin-bottom: 30px;
        }

        .contactGroup {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .icon {
            display: block;
            margin-right: 15px;
          }

          p {
            margin-bottom: 0;
            font-size: 1.1rem;
            font-family: "quicksand", sans-serif;
          }
        }

        .inputWrapper {
          margin-bottom: 15px;
        }

        button {
          display: block;
          margin-top: 15px;
          font-size: 1.1rem;
          font-family: "quicksand", sans-serif;
          font-weight: 600;
          padding: 10px 60px;
          border-radius: 12px;
          border: 2px solid #cc6328;
          background-color: rgba(0, 0, 0, 0);
          color: #cc6328;
          transition: 0.5s;

          &:hover {
            background-color: #cc6328;
            color: #fff;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .heroSection {
    padding: 0px 100px 0;
    min-height: 100vh;
    background-color: #f5f5f5;
    margin-top: -100px;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      margin: 0 auto;
      margin-top: 90px;
      max-width: 1200px;
    }

    .mainCopy {
      .copyGroup {
        position: relative;

        .pattern {
          position: absolute;
          top: -50px;
          left: -60px;
          width: 150px;
          z-index: 1;
        }
      }

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;

      h1 {
        font-size: 3.5rem;
        text-align: left;
        font-weight: 400;
        width: 85%;
        color: #cc6328;
        z-index: 3;
        position: relative;
      }

      h3 {
        width: 60%;
        font-size: 1rem;
        text-align: left;
        margin: 0;
        font-weight: 600;
        font-family: "quicksand", sans-serif;
        color: #2f2f2f;
      }

      input {
        border-radius: 35px;
        padding: 10px 10px;
        width: 300px;
        text-align: center;
        border: 1px solid #cc6328;
        margin: 0;
        margin-top: 25px;
        font-family: "quicksand", sans-serif;
        display: block;
        font-size: 1.15rem;

        &:focus {
          outline: none;
        }
      }

      button {
        font-family: "quicksand", sans-serif;
        width: 300px;
        border-radius: 35px;
        padding: 10px 10px;
        background-color: #cc6328;
        color: #fff;
        border: 1px solid #cc6328;
        margin: 0;
        margin-top: 15px;
        font-size: 1.15rem;
        font-weight: 600;

        &:focus {
          outline: none;
        }
      }
    }

    .imageGroup {
      display: flex;
      justify-content: center;
      align-items: center;

      .imgContainer {
        position: relative;

        .mainImg {
          border-top-left-radius: 30px;
          border-bottom-right-radius: 30px;
          position: relative;
          width: 350px;
          z-index: 2;
        }

        .minorImg {
          position: absolute;
          top: -70px;
          width: 140px;
          right: -70px;
          border-radius: 25px;
          z-index: 3;
        }

        .minorImg2 {
          position: absolute;
          bottom: -70px;
          width: 140px;
          left: -70px;
          border-radius: 25px;
          z-index: 3;
        }

        .firstCircle {
          position: absolute;
          top: 0px;
          width: 140px;
          right: -70px;
          z-index: 1;
        }

        .secondCircle {
          position: absolute;
          bottom: 0px;
          width: 140px;
          left: -70px;
        }
      }
    }
  }

  .featureSection {
    padding: 80px 100px;
    text-align: center;
    background-color: #f5f5f5;

    .container {
      margin: 0 auto;
      max-width: 1200px;
    }

    .featureBox {
      background-color: #ffffff;
      border-radius: 15px;
      box-shadow: 8px 15px 30px #0000001a;
      padding: 30px 15px;

      .featureIcon {
        font-size: 3.6rem;
      }

      h2 {
        margin-top: 20px;
        font-weight: 600;
        color: #212121;
        font-size: 1.45rem;
      }

      p {
        margin-bottom: 0;
        // width: 50%;
        margin: auto;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        font-weight: 600;
      }
    }
  }

  .aboutSection {
    padding: 80px 100px;
    background-color: #f4e7f2;
    text-align: left;

    .container {
      margin: 0 auto;
      max-width: 1200px;

      h5 {
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        font-weight: 600;
        color: #cc6328;
      }

      h2 {
        margin-top: 15px;
        font-weight: 600;
        color: #212121;
        font-size: 1.85rem;
        margin-bottom: 25px;
      }

      p {
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
      }

      .founderQuote {
        font-size: 1.4rem;
        font-weight: 600;
        color: #002748;
      }

      .quoter {
        font-size: 1.2rem;

        span {
          font-weight: 600;
        }
      }

      button {
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        font-weight: 600;
        padding: 10px 32px;
        margin-top: 15px;
        border-radius: 12px;
        border: 2px solid #cc6328;
        background-color: rgba(0, 0, 0, 0);
        color: #cc6328;
        transition: 0.5s;

        &:hover {
          background-color: #cc6328;
          color: #fff;
        }

        &:focus {
          outline: none;
        }
      }

      .imageGroup {
        display: flex;
        justify-content: center;
        align-items: center;

        .imageContainer {
          position: relative;

          img {
            position: relative;
            z-index: 3;
            width: 350px;
          }

          .rect {
            background-color: #841fe8;
            width: 400px;
            height: 400px;
            border-radius: 35px;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }

  .productSection {
    padding: 80px 100px;
    background-color: #f5f5f5;

    .container {
      margin: 0 auto;
      max-width: 1200px;

      h5 {
        text-align: center;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        font-weight: 600;
        color: #cc6328;
      }

      h2 {
        text-align: center;
        font-weight: 600;
        color: #212121;
        font-size: 1.85rem;
        width: 50%;
        margin: 0 auto;
        margin-bottom: 80px;
      }

      .productBox {
        background-color: #ffffff;
        box-shadow: 15px 20px 35px #00000017;
        border-radius: 14px 14px 0px 0px;
        padding: 35px 45px;
        height: 360px;

        .iconWrapper {
          height: 70px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;

          .credit {
            width: 50px;
          }

          .notes {
            width: 30px;
          }

          .advisory {
            width: 50px;
          }

          .alternative {
            width: 50px;
          }
        }

        h3 {
          font-family: "somoLight", sans-serif;
          font-weight: 600;
          font-size: 1.6rem;
          margin-top: 30px;
          margin-bottom: 18px;
        }

        p {
          font-size: 1.1rem;
          font-family: "quicksand", sans-serif;
        }
      }
    }
  }

  .repeatSection {
    padding: 80px 100px;
    background-color: #f5f5f5;

    .container {
      margin: 0 auto;
      max-width: 1200px;

      .styledBox {
        background-color: #cc6328;
        border-radius: 25px;
        padding: 80px 100px;
        position: relative;

        .circlePatternOne {
          position: absolute;
          left: -200px;
          bottom: -100%;
        }

        .circlePatternTwo {
          position: absolute;
          right: -200px;
          top: -100%;
          z-index: 1;
          display: block;
        }

        .circle {
          border-radius: 100%;
          position: absolute;
          display: block;
        }

        .firstCircle {
          width: 180px;
          height: 180px;
          background-color: #f5f5f534;
          top: -90px;
          left: -90px;
        }

        .secondCircle {
          width: 70px;
          height: 70px;
          background-color: #f5f5f534;
          top: 22.5px;
          left: 45px;
        }

        .thirdCircle {
          width: 180px;
          height: 180px;
          background-color: #f5f5f534;
          left: 29%;
          bottom: -100px;
        }

        .fourthCircle {
          width: 44px;
          height: 44px;
          background-color: #f7ceef9a;
          left: 32%;
          bottom: 56px;
        }

        .fifthCircle {
          width: 70px;
          height: 70px;
          background-color: #f49ee453;
          left: 43%;
          top: 22.5px;
        }

        .sixthCircle {
          width: 44px;
          height: 44px;
          background-color: #f7ceef9a;
          left: calc(43% + 50px);
          top: calc(70px - 22.5px);
        }

        .seventhCircle {
          width: 180px;
          height: 180px;
          background-color: #f5f5f534;
          right: 0;
          bottom: 35px;
        }

        .eigthCircle {
          width: 70px;
          height: 70px;
          background-color: #f49ee453;
          right: 20px;
          bottom: 15px;
        }

        .ninthCircle {
          width: 44px;
          height: 44px;
          background-color: #f7ceef9a;
          right: 0;
          bottom: 50px;
        }

        .access {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        h2 {
          font-size: 2.1rem;
          color: #fff;
          font-weight: 600;
          width: 90%;
          margin-bottom: 0;
          text-align: left;
        }

        input {
          display: block;
          margin: 0 auto;
          background-color: #e2e2e2;
          border: 1px solid #e2e2e2;
          text-align: center;
          border-radius: 35px;
          padding: 10px 25px;
          width: 350px;
          font-family: "quicksand", sans-serif;
          font-size: 1.1rem;

          &:focus {
            outline: none;
          }
        }

        button {
          background-color: #f5f5f5;
          border: 1px solid #f5f5f5;
          border-radius: 35px;
          display: block;
          margin: 0 auto;
          margin-top: 20px;
          padding: 12px 25px;
          width: 350px;
          font-family: "quicksand", sans-serif;
          font-size: 1.1rem;
          font-weight: 600;
          color: #cc6328;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .contactSection {
    padding: 40px 100px 80px;
    background-color: #f5f5f5;

    .container {
      max-width: 900px;
      margin: 0 auto;

      h2 {
        text-align: center;
        font-weight: 600;
        color: #cc6328;
        font-size: 1.9rem;
        margin-bottom: 60px;
      }

      .contactContainer {
        background-color: #fff;
        position: relative;
        z-index: 5;
        box-shadow: 15px 20px 35px #00000017;
        border-radius: 15px;

        .addressDetails,
        .contactForm {
          padding: 40px 50px;
        }

        .addressCol {
          border-right: 1px solid #cc6328;
        }

        h3 {
          font-size: 1.65rem;
          color: #cc6328;
          font-family: "quicksand", sans-serif;
          font-weight: 700;
          margin-bottom: 30px;
        }

        .contactGroup {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .icon {
            display: block;
            margin-right: 15px;
          }

          p {
            margin-bottom: 0;
            font-size: 1.1rem;
            font-family: "quicksand", sans-serif;
          }
        }

        .inputWrapper {
          margin-bottom: 15px;
        }

        button {
          display: block;
          margin-top: 15px;
          font-size: 1.1rem;
          font-family: "quicksand", sans-serif;
          font-weight: 600;
          padding: 10px 60px;
          border-radius: 12px;
          border: 2px solid #cc6328;
          background-color: rgba(0, 0, 0, 0);
          color: #cc6328;
          transition: 0.5s;

          &:hover {
            background-color: #cc6328;
            color: #fff;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}