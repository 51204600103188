// .rightPanel {
//   width: 70%;
//   overflow: hidden;
//   // height: 80vh;
// }

.header {
  overflow: hidden;
}

.openMenu {
  cursor: pointer;
  border: 1px solid #cc6328;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  // padding: 5px;
  padding-left: 10px;
  position: relative;
  left: -10px;
  display: flex;
  align-items: center;
  // justify-content: center;
}

.openMenu:hover {
  left: -2px;
}

.description {
  border-bottom: 1px solid #e2e7eb;
  padding: 0 20px 0 0;
}

.description h2 {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #cc6328;
  // margin-left: 20px;
}

.duration {
  display: flex;
  align-items: center;
}

.duration div {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.duration div p {
  margin-left: 5px;
  margin-bottom: 0;
  color: #878787;
  font-size: 13px;
}

.outlineCont {
  padding: 20px;
  overflow: auto;
  height: 65vh;
}

.outlineCont::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.outlineCont::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
  visibility: hidden;
}

.outlineCont:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.outline {
  padding: 20px;
  border: 1px solid #00a5a2;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: all 300ms;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
    box-shadow: 10px 10px 10px #bcbcbc;
  }
}

.parent {
  display: flex;
  align-items: center;
  color: #1f1f1f;
}

.parent h4 {
  font-size: 16px;
  margin-bottom: 0;
  margin-left: 5px;
}

.children {
  margin-left: 20px;
}

.child {
  display: flex;
  align-items: center;
  color: #535353;
  margin: 10px;
}

.child h4 {
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 5px;
}

.actualContent {
  margin-left: 40px;
}

.actContent div {
  display: flex;
  align-items: center;
  color: #535353;
  margin-top: 10px;
}

.actContent div h5 {
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
}

.actContent p {
  font-size: 12px;
  margin-left: 30px;
  margin-top: 5px;
  color: #878787;
}

.route {
  font-size: 0.9rem !important;
  padding: 20px 0 10px;
}

.route span {
  color: #00a5a2;
  cursor: pointer;
}

.contentNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.nav {
  display: flex;
  align-items: center;
}

.navigations {
  display: flex;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
  color: #0056d2;
}

.navigations h3 {
  margin: 0;
  padding: 0;
}

@media (max-width: 480px) {
  .description {
    padding-left: 5px;
  }

  .description h2 {
    font-size: 1.2rem;
  }
}
