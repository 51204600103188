.body {
    padding-left: 40px;
    padding-right: 40px;
}

.headerText {
    font-weight: 600;
    margin-bottom: 0;
}

.success {
    padding: 20px;
    text-align: center;
    h4 {
        font-family: var(--fontFamilyTwo);
        font-weight: 600;
        margin-top: 20px;
    }
}