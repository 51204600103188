// .container {
//   // width: 650px;
//   width: 100%;
//   margin: 0 auto;
// }

.button {
  margin-bottom: 20px;
}

.applicationComplete {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 8px 15px 30px #0000001a;
  text-align: center;

  h2 {
    font-weight: 700;
    font-size: 24px;
    color: #cc6328;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  p {
    font-size: 0.95rem;
    margin-bottom: 5px;
    font-family: "quicksand", sans-serif;
  }

  div.quizListHold {
    display: flex;
    flex-wrap: wrap;

    div.quizList {
      width: 100%;
      display: flex;
      // border: 1px solid #00a5a2;
      padding: 7px;
      border-radius: 7px;
      margin: 10px auto;
      align-items: center;
      transition: all 500ms;
      position: relative;

      &.quizListHide {
        display: none;
      }

      &.quizListoptional {
        border-style: dashed;
      }

      &:hover,
      &.quizListActive {
        // transform: scale(1.05);
        // box-shadow: 10px 10px 10px #bcbcbc;
      }

      div.quizListInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0px 5px;

        label {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 2px;

          p {
            margin-bottom: 10px;

            g {
              color: #cc6328;
            }
          }

          textarea,
          input {
            border-radius: 5px;
            display: flex;
            border: 2px solid #dddddd;
            padding: 3px;
            resize: none;

            &:focus {
              outline-color: #ffc83b;
              // outline-style: double;
              box-shadow: 0 0 0 0.2rem rgba(255, 200, 59, 0.5);
            }
          }

          input {
            font-size: 0.8rem;
          }

          select {
            padding: 5px;
          }

          .check {
            display: flex;
            margin: 7px 0;
            // align-items: end;
          }

          .check .other {
            border: none;
            border-bottom: 2px solid #e2e7eb;
            border-radius: 0;
            margin-left: 20px;
            outline: none !important;
            box-shadow: none;
            width: 100%;
          }

          .check label {
            font-size: 0.8rem;
            margin-left: 5px;
          }

          .check input:active,
          .check input:focus {
            outline: none !important;
          }

          .imgContainer {
            width: 100%;
            margin: 10px 0 20px;
          }

          .imgContainer img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        span {
          border-bottom: 1px dashed #bcbcbc;
        }
      }

      input {
        display: none;
      }
    }

    div.advisorList {
      width: 100%;
      display: flex;
      border: 1px solid #00a5a2;
      padding: 7px;
      border-radius: 7px;
      margin: 10px auto;
      align-items: center;
      transition: all 300ms;
      cursor: pointer;
      position: relative;

      &:hover {
        transform: scale(1.05);
        box-shadow: 10px 10px 10px #bcbcbc;
      }

      .icon {
        font-size: 3em;

        &.iconChecked {
          color: #00a5a2;
        }

        &.iconHidden {
          display: none;
        }

        &.iconCheckedHover {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          animation: bigger 500ms linear forwards;
        }
      }

      .iconLoader {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      div.advisorListInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0px 5px;

        span {
          border-bottom: 1px dashed #bcbcbc;
        }
      }

      input {
        display: none;
      }
    }
  }

  div.quizBtnLdr {
    button.surveySubmitBtn {
      border: 3px double #cc6328;
      padding: 5px 15px;
      border-radius: 5px;
      background-color: #ffffff;
      color: #00a5a2;
      font-size: 1.4em;
      font-weight: 700;
      // display: none;
      margin: 40px 0;

      &:focus {
        outline-color: #000000;
        outline-style: double;
      }
    }

    span.surveySubmitLoader {
      display: none;
    }
  }

  nav.playbookNav {
    text-align: left;
    margin: 7px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    Button {
      margin: 5px !important;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .applicationComplete {
    // width: 550px;
    margin: 0px auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    // margin-top: 60px;
    text-align: center;

    h2 {
      //   font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }

    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .applicationComplete {
    width: 650px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    // margin-top: 60px;
    text-align: center;

    h2 {
      font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }

    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }
}