.welcomeGroup {
  h2 {
    font-weight: 700;
    color: #cc6328;
    font-size: 1.7rem;
  }
  p {
    font-family: "quicksand", sans-serif;
    font-size: 1rem;
  }
}

.loanStatus {
  margin-top: 30px;
  padding: 20px 30px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 8px 15px 30px #0000001a;
  background-color: #ffffff;
  .innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      display: none;
    }
    .mainContent {
      padding-left: 0;
      margin: 0 auto;
      text-align: center;
    }
    h3 {
      font-weight: 600;
      margin-bottom: 10px;
      color: #212121;
    }
  }
  .activeStyle {
    h3 {
      font-size: 1.45rem;
      span {
        font-size: 0.9rem;
        color: #cc6328;
        font-style: italic;
      }
    }
    .borderClass {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
    }
    .statusBoard {
      margin-top: 30px;
      background-color: #ebebeb;
      border-radius: 10px;
      padding: 18px 28px;
      .loanData {
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 10px 0;
        h6 {
          color: #cc6328;
          font-weight: 600;
          font-size: 16px;
          font-family: "quicksand", sans-serif;
        }
        h1 {
          font-size: 2.3rem;
          margin-bottom: 0;
          font-weight: 700;
          color: #606060;
        }
      }
    }
  }
}

.otherProducts {
  margin-top: 50px;
  .borderClass {
    border-bottom: 4px solid #841fe8;
  }
  .border2Class {
    border-bottom: 4px solid #015514;
  }
  .product {
    .productIcon {
      height: 50px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    background-color: #ffffff;
    border-radius: 15px 15px 0 0;
    text-align: center;
    box-shadow: 8px 15px 30px #0000001a;
    padding: 28px 32px;
    h3 {
      font-size: 1.4rem;
      margin-top: 20px;
      font-weight: 600;
      font-family: "somoLight", sans-serif;
      color: #cc6328;
    }
    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.1rem;
    }
    .productBtn {
      border: none;
      background-color: #fff;
      font-size: 1.15rem;
      color: #cc6328;
      font-family: "quicksand", sans-serif;
      font-weight: 600;
      border-bottom: 2px solid #cc6328;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .welcomeGroup {
    h2 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.1rem;
    }
  }

  .loanStatus {
    margin-top: 40px;
    padding: 30px 40px;
    .innerContainer {
      .mainContent {
        text-align: left;
        margin: 0;
      }
      img {
        display: block;
        height: 240px;
      }
      h3 {
        font-size: 1.75rem;
      }
    }
    .activeStyle {
      h3 {
        font-size: 1.65rem;
      }
      .borderClass {
        border-bottom: none;
        border-right: 1px solid #cc6328;
      }
      .statusBoard {
        margin-top: 30px;
        padding: 18px 22px;
        .loanData {
          text-align: left;
          h1 {
            font-size: 2.4rem;
          }
        }
      }
    }
  }

  .otherProducts {
    .product {
      height: 350px;
      .productBtn {
        font-size: 1.05rem;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .welcomeGroup {
    h2 {
      font-size: 2rem;
    }
  }

  .loanStatus {
    .innerContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mainContent {
        padding-left: 15px;
      }
      img {
        height: 280px;
      }
      h3 {
        font-size: 1.75rem;
      }
    }
    .activeStyle {
      h3 {
        font-size: 1.75rem;
      }
      .borderClass {
        border-right: 1px solid #cc6328;
      }
      .statusBoard {
        .loanData {
          h1 {
            font-size: 2.5rem;
          }
        }
      }
    }
  }

  .otherProducts {
    .product {
      .productIcon {
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      background-color: #ffffff;
      border-radius: 15px 15px 0 0;
      text-align: center;
      box-shadow: 8px 15px 30px #0000001a;
      padding: 28px 32px;
      h3 {
        font-size: 1.4rem;
        margin-top: 20px;
        font-weight: 600;
        font-family: "somoLight", sans-serif;
        color: #cc6328;
      }
      p {
        font-family: "quicksand", sans-serif;
        font-size: 1.1rem;
      }
      .productBtn {
        border: none;
        background-color: #fff;
        font-size: 1.15rem;
        color: #cc6328;
        font-family: "quicksand", sans-serif;
        font-weight: 600;
        border-bottom: 2px solid #cc6328;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
