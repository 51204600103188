.card {
  width: 80%;
  height: 180px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  // margin: 20px auto;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  margin: 20px 0;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-size: 17px;
  color: #212153;
}

.value {
  font-size: 19px;
  color: #a9b1be;
}

@media (max-width: 1200px) {
  .title {
    font-size: 14px;
  }

  .value {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 17px;
  }

  .value {
    font-size: 19px;
  }
}
