.heading {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: flex-start;

  nav {
    Button {
      margin: 9px;
    }
  }

  h2 {
    font-size: 1.7rem;
    color: #cc6328;
    font-weight: 700;
    margin-bottom: 0;
  }

  h3.cohortInfo {
    font-size: 1rem;
    color: #333333;
    font-weight: 500;
    margin-bottom: 0;
  }

  p {
    font-family: "quicksand", sans-serif;
    font-size: 1.1rem;
  }

  .timer {
    transform: scale(0.7);
    position: absolute;
    display: flex;
    left: -12px;
  }
}

.applicationComplete {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 8px 15px 30px #0000001a;
  margin-top: 40px;
  text-align: center;

  h2 {
    font-weight: 700;
    color: #cc6328;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  p {
    font-size: 0.95rem;
    margin-bottom: 5px;
    font-family: "quicksand", sans-serif;
  }

  div.advisorsListHold {
    display: flex;
    flex-wrap: wrap;

    div.advisorList {
      width: 100%;
      display: flex;
      border: 1px solid #00a5a2;
      padding: 7px;
      border-radius: 7px;
      margin: 10px auto;
      align-items: center;
      // transition: all 300ms;
      cursor: pointer;
      position: relative;

      &:hover {
        transform: scale(1.05);
        box-shadow: 10px 10px 10px #bcbcbc;
      }

      .icon {
        font-size: 3em;

        &.iconChecked {
          color: #00a5a2;
        }

        &.iconHidden {
          display: none;
        }

        &.iconCheckedHover {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          // animation: bigger 500ms linear forwards;
        }
      }

      .iconLoader {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      div.advisorListInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0px 5px;

        span {
          border-bottom: 1px dashed #bcbcbc;
        }
      }

      input {
        display: none;
      }
    }
  }

  nav.playbookNav {
    text-align: left;
    margin: 7px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    Button {
      margin: 5px !important;
    }
  }
}

div.advisorsListHold {
  display: flex;
  flex-wrap: wrap;

  div.advisorList {
    width: 100%;
    display: flex;
    border: 1px solid #00a5a2;
    padding: 7px;
    border-radius: 7px;
    margin: 10px auto;
    align-items: center;
    // transition: all 300ms;
    cursor: pointer;
    position: relative;

    // &:hover {
    //   transform: scale(1.05);
    //   box-shadow: 10px 10px 10px #bcbcbc;
    // }

    .icon {
      font-size: 3em;

      &.iconChecked {
        color: #00a5a2;
      }

      &.iconHidden {
        display: none;
      }

      &.iconCheckedHover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        // animation: bigger 500ms linear forwards;
      }
    }

    .iconLoader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div.advisorListInfo {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0px 5px;

      span {
        border-bottom: 1px dashed #bcbcbc;
      }
    }

    input {
      display: none;
    }
  }
}

.scoresLoading {
  margin: 40px auto;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  width: 550px;
  padding: 40px 60px;
  box-shadow: 8px 15px 30px #0000001a;
}

.scoresLoading h2 {
  color: #cc6328;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 720px) {
  .heading {
    nav {
      Button {
        margin: 9px 2px;

        span.btnTxt {
          display: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .heading {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: flex-start;

    h2 {
      font-size: 2rem;
      margin-bottom: 8px;
    }
  }

  .applicationComplete {
    width: 550px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    margin-top: 60px;
    text-align: center;

    h2 {
      font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }

    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }
}

@media (max-width: 700px) {
  .scoresLoading {
    width: 100%;
  }

  .scoresLoading h2 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) {
  .heading {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;

    h2 {
      font-size: 2rem;
      color: #cc6328;
      font-weight: 700;
    }

    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.1rem;
    }
  }

  .applicationComplete {
    width: 550px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    margin-top: 60px;
    text-align: center;

    h2 {
      font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }

    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }
}

@media (max-width: 480px) {
  .scoresLoading {
    padding: 20px;
  }
}
