.dashboard {
  margin-top: 20px;
  overflow-x: auto;
  width: 100%;
  border-radius: 15px;
  box-shadow: 8px 15px 30px #0000001a;
  padding: 24px 22px;
  background-color: #ffffff;
  position: relative;
  min-height: 70vh;
}

.welcomeAcc h2 {
  font-size: 2rem;
  color: #212153;
}

.content {
  display: flex;
}

.leftPanel {
  width: 60%;
}

.rightPanel {
  width: 40%;
  padding: 0 10px;
}

.progressCont {
  height: 300px;
  border: 1px solid #00a5a2;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  margin: 20px;
}

.progressTitle {
  display: flex;
  align-items: center;
}

.progressTitle h5 {
  color: #212153;
  font-weight: 700;
  margin-bottom: 0;
  //   font-size: 17px;
}

.progressTitle div {
  width: 10px;
  height: 10px;
  background-color: #ffc83b;
  border-radius: 50%;
  margin-right: 2px;
}

.progressButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.progress {
  width: 100%;
  height: 230px;
}

.buttonCont {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.buttonCont h4 {
  margin-top: 10px;
  font-size: 16px;
}

.title {
  margin: 40px 20px 0;
  color: #212153;
  font-weight: 700;
}

.whereStopped {
  margin: 20px 20px 0;
  display: flex;
  border: 1px solid #00a5a2;
  cursor: pointer;
}

.whereStopped:hover {
  background-color: #f7f7f7;
}

.type {
  width: 100px;
  background-color: #00a5a231;
  display: flex;
  align-items: center;
  justify-content: center;
}

.learningTitle {
  padding: 10px 20px;
  height: 80px;
  display: flex;
  align-items: center;
}

.learningTitle h5 {
  font-size: 1rem;
}

.learningTitle p {
  margin-top: 30px;
  font-size: 14px;
}

.assignmentsCont {
  // margin-bottom: 20px;
}

.assignments {
  margin: 20px;
}

.assignment {
  width: 100%;
  display: flex;
  border: 1px solid #00a5a2;
  padding: 7px;
  border-radius: 7px;
  margin: 10px auto;
  align-items: center;
  transition: all 300ms;
  cursor: pointer;
  position: relative;

  &:hover {
    transform: scale(1.05);
    box-shadow: 10px 10px 10px #bcbcbc;
  }

  .icon {
    margin-right: 10px;

    &.iconChecked {
      color: #00a5a2;
    }

    &.iconHidden {
      display: none;
    }

    &.iconCheckedHover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      animation: bigger 500ms linear forwards;
    }
  }

  .iconLoader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.advisorListInfo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0px 5px;

    span {
      border-bottom: 1px dashed #bcbcbc;
    }
  }
}

.assignmentsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.seeAllAssignments {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 20px 0;
  cursor: pointer;
}

.seeAllAssignments p {
  margin-bottom: 0;
  margin-right: 10px;
}

.button {
  width: 110px;
  border: none;
  border-radius: 5px;
  margin: 10px 0 0;
  font-size: 13px;
}

.calendarCont {
  margin: 20px;
}

.calendar {
  width: 100%;
  height: 300px;
}

.react-calendar {
  width: 100% !important;
}

.myCalendar {
  width: 100% !important;
  border: none !important;
  border-radius: 10px !important;
  padding: 10px !important;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1) !important;
}

.myCalendar .react-calendar__tile {
  background: #f0f0f0 !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.myCalendar .react-calendar__tile--active {
  background: #00a5a2 !important;
  color: white !important;
}

.class {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  border-left: 10px solid #00a5a2;
  margin: 20px;
}

.topic p {
  font-size: 14px;
  margin-right: 20px;
}

.detailedTime {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 5px 0;
}

.detailedTime p {
  margin-bottom: 0;
  margin-left: 5px;
}

.openMeeting {
  transform: rotate(45deg);
  float: right;
  cursor: pointer;
}

.loader {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px auto;
}
.errorQuestions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .dashboard {
    padding: 15px;
  }

  .welcomeAcc h2 {
    font-size: 1.3rem;
  }

  .progressCont {
    height: 300px;
    padding: 10px;
    margin: 10px;
  }

  .progress {
    width: 100%;
    height: 230px;
  }

  .leftPanel {
    width: 40%;
  }

  .rightPanel {
    width: 60%;
  }

  .calendarCont {
    margin: 10px;
  }

  .title {
    margin: 20px 10px 0;
    // font-size: 1rem;
    font-weight: 700;
  }

  .whereStopped {
    height: 100px;
    margin: 20px 10px 0;
  }

  .learningTitle {
    padding: 10px 20px;
  }

  .learningTitle h5 {
    font-size: 1rem;
  }

  .learningTitle p {
    margin-top: 15px;
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .assignments {
    margin: 10px;
  }

  .seeAllAssignments {
    margin: 20px 20px 0;
  }

  .seeAllAssignments p {
    font-size: 0.8rem;
  }

  .class {
    padding: 15px;
    margin: 20px 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .welcomeAcc h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .progressCont {
    margin: 10px;
  }

  .calendarCont {
    margin: 10px;
  }

  .calendar {
    width: 100%;
    height: 300px;
  }

  .whereStopped {
    margin: 20px 10px 0;
  }

  .assignments {
    margin: 20px 10px;
  }

  .title {
    margin: 40px 10px 0;
  }

  .seeAllAssignments {
    margin: 40px 10px 0;
  }

  .class {
    margin: 20px 10px;
  }
}

@media (max-width: 767px) {
  .welcomeAcc h2 {
    font-size: 1rem;
    font-weight: 700;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .leftPanel {
    width: 100%;
  }

  .rightPanel {
    width: 100%;
    padding: 0 10px;
  }

  .calendarCont {
    margin: 20px 0;
  }

  .class {
    padding: 20px;
    margin: 20px 0;
  }
}

@media (max-width: 480px) {
  .rightPanel {
    padding: 0;
  }

  .progressCont {
    margin: 0;
  }

  .title {
    margin: 40px 0 0;
  }

  .whereStopped {
    margin: 20px 0 0;
  }

  .assignments {
    margin: 20px 0;
  }
}

// @media (min-width: 1920px) {
// }

// @media (min-width: 1200px) and (max-width: 1919px) {
// }

// @media (min-width: 992px) and (max-width: 1199px) {
// }

// @media (min-width: 768px) and (max-width: 991px) {
// }

// @media (min-width: 481px) and (max-width: 767px) {
// }

// @media (max-width: 480px) {
// }
