:root {
  --fontColorOne: #212121;
  --fontFamilyOne: "quicksand";
  --fontFamilyTwo: "Kanit";
  --backgroundOne: " #F5F5F5";
}

.detailFields {
  padding: 40px 60px;
}

.disburse {
  h3 {
    font-family: var(--fontFamilyOne);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 25px;
  }
}

.salary {
  h3 {
    font-family: var(--fontFamilyOne);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: 40px;
  }
}

.officeAddress {
  h3 {
    font-family: var(--fontFamilyOne);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: 40px;
  }
}

.loanTable {
  margin-top: 60px;
  .btn {
    float: right;
    margin-bottom: 40px;
  }
  .tableCard {
    background-color: #fff;
    clear: both;
    border-radius: 10px;
    box-shadow: 8px 15px 30px #0000001a;
    padding: 24px 24px;
    table {
      border-radius: 10px;
      th {
        border: none;
        color: #fff;
        // border-radius: 10px;
      }
      thead {
        background-color: #cc6328;
        border-radius: 10px !important;
        border: none;
      }
      .loanId {
        color: #0075d7;
        font-weight: 600;
        a:hover {
          text-decoration: none;
          color: #0075d7;
        }
      }
    }
  }
}

.tableFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .rowsInput {
    p {
      margin-bottom: 0;
      margin-right: 14px;
    }
    select {
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 0.25rem;
      padding: 7px 14px;
      width: 70px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #f0f0f0
        url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='12' height='12' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>")
        no-repeat;
      background-position: right 15px top 50%;
      &:focus {
        outline: none;
      }
    }
    display: flex;
    align-items: center;
  }
  .pagination {
    margin-left: 30px;
    justify-content: flex-end;
    margin-bottom: 0;
    .active {
      span {
        background-color: #cc6328 !important;
        border: 1px solid #cc6328;
      }
    }
    a {
      color: #000;
    }
  }
}

.nullList {
  text-align: center;
  width: 100%;
  padding: 20px 0;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
}

.profileImg {
  text-align: center;
  margin-bottom: 60px;
  img {
    width: 155px;
    height: 155px;
    border-radius: 100%;
    object-fit: cover;
  }
  .placeholder {
    width: 155px;
    height: 155px;
    border-radius: 100%;
    background-color: #c4c4c4;
    margin: 0 auto;
  }
}
