:root {
  --fontColorOne: #212121;
  --fontFamilyOne: "quicksand";
  --fontFamilyTwo: "somoHead";
  --backgroundOne: " #F5F5F5";
}

.profileBox {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 8px 15px 30px #0000001a;
  margin-top: 50px;

  .borderStyle {
    border-right: 1px solid #707070;
  }

  .tabMenu {
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabCol {
    &:nth-child(1) {
      padding-right: 0px;
    }

    &:nth-child(2) {
      padding-left: 0px;
      padding-right: 0px;
    }

    &:nth-child(3) {
      padding-left: 0px;
    }
  }

  .activeTab1 {
    background-color: #fff;
    border-top-left-radius: 15px;
  }

  .activeTab2 {
    background-color: #fff;
  }

  .activeTab3 {
    background-color: #fff;
    border-top-right-radius: 15px;
  }

  .header {
    background-color: #ebebeb;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    p {
      padding: 14px 0px;
      color: #606060;
      font-family: "quicksand", sans-serif;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  .body {
    padding: 40px 30px;

    h2 {
      margin-bottom: 40px;
      text-align: center;
      font-size: 1.7rem;
    }

    .security {
      padding: 0;
    }

    .passMismatch {
      margin: 10px 0;
      color: #ff0000;
      display: flex;
      justify-content: flex-start;
    }

    .profilePictureSection {
      width: 130px;
      height: 130px;
      margin: 0 auto;
      background-color: #f5f5f5;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    .uploadBtn {
      margin-bottom: 40px;
      margin-top: 15px;

      label {
        color: #cc6328;
        text-decoration: underline;
        font-size: 1.1rem;
        font-family: var(--fontFamilyOne);
        font-weight: 600;
        cursor: pointer;
        text-align: center;
      }
    }

    .payment {
      .btnGroup {
        display: flex;
        justify-content: center;

        button {
          margin: 0 10px;
          color: #cc6328;
          font-family: var(--fontFamilyOne);
          font-weight: 600;
          padding: 8px 18px;
          border-radius: 10px;
          border: 1px solid #606060;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.1rem;
          background-color: #ebebeb;
          box-shadow: 8px 15px 30px #0000001a;

          &:focus {
            outline: none;
          }

          &.activeMenu {
            color: #ebebeb;
            background-color: #cc6328;
            border: 1px solid #cc6328;
          }
        }

        .icon {
          font-size: 1.6rem;
          margin-right: 18px;
        }
      }

      .content {
        min-height: 200px;

        .addCardRemita {
          width: 100%;
          height: 150px;
          border: 1px dashed #707070;
          border-radius: 15px;
          margin: 0 auto;
          margin-top: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          .cardInner {
            p {
              font-family: var(--fontFamilyOne);
              font-weight: 600;
              font-size: 1.2rem;
            }
          }
        }

        .monoLink {
          width: 100%;
          height: 220px;
          margin: 0 auto;
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          h4 {
            font-size: 1.4rem;
            width: 100%;
            font-family: var(--fontFamilyOne);
            font-weight: 600;
            margin: 0 auto;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .profileBox {
    margin-top: 80px;

    .header {
      p {
        padding: 18px 0px;
        font-size: 1.2rem;
      }
    }

    .body {
      padding: 50px 80px;

      h2 {
        font-size: 2rem;
      }

      .security {
        padding: 0 80px;
      }

      .profilePictureSection {
        width: 170px;
        height: 170px;
      }

      .payment {
        .btnGroup {
          button {
            margin: 0 30px;
            padding: 10px 35px;
            font-size: 1.1rem;
          }

          .icon {
            font-size: 2rem;
            margin-right: 25px;
          }
        }

        .content {
          .addRemita {
            margin: 0 auto;
            margin-top: 40px;
            max-width: 400px;
          }

          min-height: 200px;

          .monoLink {
            width: 450px;
            height: 220px;
            border: 1px solid #707070;
            border-radius: 15px;
            margin: 0 auto;
            margin-top: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            h4 {
              font-size: 1.55rem;
              width: 80%;
            }
          }
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .profileBox {
    .body {
      .security {
        padding: 0 140px;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}