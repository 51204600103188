.modalContainer {
  //   height: 300px;
  padding: 30px;
  h2 {
    font-size: 24px;
    width: 70%;
    font-weight: 600;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 992px) {
  .modalContainer {
    //   height: 300px;
    padding: 40px;
    h2 {
      font-size: 28px;
      width: 70%;
      font-weight: 600;
      margin-bottom: 35px;
    }
  }
}
