.button {
    font-family: 'quicksand', sans-serif;
    font-size: 1.15rem;
    font-weight: 700;
    border-radius: 4px;
    border: none;
    &:focus {
        outline: none;
    }
    &:hover {
        background-color: rgba(116, 23, 99, 0.9);
    }
    &:disabled, &[disabled] {
        opacity: 0.7;
    }
}