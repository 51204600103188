.note {
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid #00a5a2;
  border-radius: 5px;
  flex: 1 1 300px;
  // width: 230px;
  // height: 121px;
  transition: all 300ms;
  cursor: pointer !important;

  &:hover {
    transform: scale(1.05);
    box-shadow: 10px 10px 10px #bcbcbc;
  }
}

.note h5 {
  color: #00a5a2;
  font-weight: bold;
  font-size: 1rem;
}

.noteCont {
  max-height: 121px;
  overflow: hidden;
}

.note p {
  font-size: 0.8rem;
  line-height: 170%;
  color: #000000;
  margin: 0;
}
