.downloadButtons {
    -webkit-appearance: none;
    outline: none;
    height: 55px;
    width: 55px;
    background-color: #fff;
    border-radius: 8px;
    border: 5px solid #ffffff44;
    box-shadow:
        6px 6px 10px -1px rgba(0, 0, 0, 0.15),
        -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(0, 0, 0, 0);
    transition: transform 0.5s;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    cursor: pointer;
    margin: 0 5px;
}

.downloadButtons:hover {
    box-shadow:
        inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
        inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
        -0.5px -0.5px 0px rgba(255, 255, 255, 1),
        0px 12px 10px -10px rgba(0, 0, 0, 0.01);
    border: 1px solid rgba(0, 0, 0, 0.01);
    transform: translateY(2px);
}

.downloadButtons p {
    font-size: 13px;
    margin-top: 6px;
}

@media (max-width: 480px) {
    .downloadButtons p {
        font-size: 13px;
    }
}

@media (max-width: 371px) {
    .downloadButtons {
        height: 30px;
        width: 30px;
        padding-top: 0px;
        margin-right: 10px;
    }

    .downloadButtons p {
        display: none;
    }
}