.back {
  margin-bottom: 20px;
  margin-left: 20px;
}

.back a {
  text-decoration: none;
}

@media (min-width: 992px) {
  .back {
    display: none;
  }
}

.filterCont {
  position: relative;
}

.clearIcon {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.filterCont input {
  padding-right: 30px;
  height: 30px;
}

.copy {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  background-color: #fff;
  z-index: 1;
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  outline: none;
  border-radius: 6px;
  border-left: 3px solid#07BC0C;
  min-width: 200px;
  transition: transform 0.8s;
}

.copy p {
  margin-bottom: 0;
}

.printHeader {
  text-align: center;
  margin: 20px 0;
  display: none;
}

.printHeader h1 {
  font-weight: bold;
}

.downloadBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.text {
  margin-left: 10px;
}

.tableBody::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.tableBody::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
  visibility: hidden;
}

.tableBody:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.buttonSelect {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 0 20px;
  margin-bottom: 20px;
  display: none;
}

.downloadContainer {
  margin: 0 15px;
  width: 30%;
}

.downloadContainer label {
  color: #212153;
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 14px;
}

.downloadContainer .select {
  margin-left: 0;
}

.filterContainer {
  width: 30%;
}

.filterContainer label {
  color: #212153;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 14px;
}

.filterContainer .select {
  margin-left: 0;
}

.selectContainer {
  margin: 0 15px;
  width: 30%;

  &.selectContainerHide {
    display: none;
  }
}

.selectContainer label {
  color: #212153;
  font-weight: bold;
  margin-left: 10%;
  margin-bottom: 10px;
}

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32"%3E%3Cpath d="M0 6h32v20H0z" fill="%23334" /%3E%3Cpath d="M10 16l6-6 6 6z" fill="#FFF" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 12px 8px;
  padding-right: 2rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  height: 38px;
  width: 90%;
  max-width: 90%;
  margin-left: 10%;
  z-index: 1;
}

.select:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(255, 200, 59, 0.5);
}

.formControl {
  display: block;
  //width: 230px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.digikuaButton {
  display: flex;
  justify-content: start;
  // justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

// .digikuaButton {
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
//   grid-gap: 10px;
//   gap: 10px;
// }

.btnTxt {
  margin: 0 5px;
  font-size: 16px;
}

@media (min-width: 481px) {
  .cashflowElementsSelect {
    display: none;
  }
}

@media (max-width: 481px) {
  .selectContainer.selectContainerHide.selectContainerShow {
    display: block;
  }
}

@media (max-width: 910px) {
  .digikuaButton {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  // .btnTxt {
  //     font-size: 11px;
  // }
}

@media (min-width: 992px) and (max-width: 1170px) {
  .btnTxt {
    font-size: 11px;
  }
}

@media (max-width: 768px) {
  .btnTxt {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .digikuaButton {
    display: none;
  }
}

.operationFunctions {
  margin: 20px 0;
}

.operationFunctions a {
  text-decoration: none;
  margin-right: 25px;
  margin-left: 15px;
  font-size: 18px;
  color: #00a5a2;
}

.tableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 20px;

  background-position: center;
  background-size: cover;
}

th {
  font-size: 13px;
  color: #212153;
}

td {
  font-size: 13px;
}

table,
th,
td {
  padding: 0.8rem;
  border-collapse: collapse;
  text-align: left;
}

td a {
  text-decoration: none;
  color: #00a5a2;
  background-color: transparent;
}

div.table {
  width: 100%;
  height: 90%;
  // background-color: rgba(255, 255, 255, 0.5);
  background-color: #ffffff;
  box-shadow: 0 0.4rem 0.8rem #0005;
  border-radius: 10px;
  overflow: hidden;
}

.tableHeader {
  width: 100%;
  height: 10%;
  background-color: #fff4;
  padding: 0.8rem 1rem;
}

.downLoadFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableBody {
  width: 95%;
  max-height: 60vh;
  background-color: #fff5;

  margin: 1.6rem auto;
  margin-top: 0.8rem;
  border-radius: 0.6rem;

  overflow: auto;
}

table {
  width: 100%;
}

thead,
th {
  position: sticky;
  top: 0;
  //left: 0;
  background-color: #fff;
}

.noData {
  text-align: center;
}

.AddProductModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modalHeader {
  padding: 1rem;
}

.modalHeader h4 {
  color: #212153;
  font-size: 1.5rem;
}

.modalBody {
  padding: 1.25rem 2rem;
  width: 100%;
}

.modalFooter {
  padding: 0.75rem;
}

.addProductForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.modalBody label {
  font-size: 1rem;
  color: #212153;
}

tbody tr:hover {
  background-color: #0000000b;
}

@media (max-width: 1000px) {
  td {
    min-width: 10rem;
  }
}

a {
  text-decoration: none;
}

@media (max-width: 1200px) {
  .selectContainer {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .selectContainer {
    width: 100%;
    margin-top: 20px;
  }

  .selectContainer label {
    font-size: 13px;
  }

  .downloadContainer {
    width: 40%;
  }

  .filterContainer {
    width: 40%;
  }
}

@media (max-width: 640px) {
  .downLoadFilter {
    align-items: start;
    justify-content: start;
    flex-direction: column;
  }

  .downloadContainer {
    width: 100%;
    margin-bottom: 20px;
  }

  .filterContainer {
    width: 320px;
    margin-left: 5px;
  }

  // .filterCont {
  //     width: 100%;
  // }

  .select {
    max-width: 100%;
  }

  .filterCont input {
    width: 100%;
  }

  .downloadBtns {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .filterContainer {
    width: 100%;
  }

  .cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .buttonSelect {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    display: block;
  }

  .selectContainer label {
    margin-left: 0;
  }

  .selectContainer select {
    margin-left: 0;
  }

  select {
    margin-left: 0;
  }

  .operationFunctions {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  .operationFunctions a {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .addProductForm {
    grid-template-columns: repeat(1, 1fr);
  }

  .copy p {
    font-size: 12px;
  }

  .clearIcon {
    right: 20px;
  }
}

@media (max-width: 420px) {
  .filterSelect {
    min-width: 100%;
  }
}

@media (min-width: 421px) and (max-width: 480px) {
  .clearIcon {
    right: 50px;
  }
}

@media (max-width: 371px) {
  .downloadBtns {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    margin-left: 0;
  }

  .filterContainer {
    width: 215px;
    margin-left: 0;
  }

  .select {
    max-width: 100%;
  }

  .filterCont input {
    width: 100%;
  }
}

@media print {
  .tableHeader {
    display: none;
  }

  table {
    visibility: visible;
    margin: 0;
    width: 100%;
  }

  .tableBody {
    max-height: 100000px !important;
  }

  .printHeader {
    display: block;
  }
}
