.employerInfo {
    .inputHint {
        font-size: 0.75rem !important;
        color: #cc6328;
        text-align: left;
    }
    h3 {
        font-family: 'somoHead', sans-serif;
        margin-top: 40px;
    }
}

.loaderWrapper {
    margin-top: 40px;
}