.messageCont {
  display: flex;
  flex-direction: column;
}

.topBar {
  padding: 30px 20px 20px 0;
  border-bottom: 1px solid #e2e7eb;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
}

.openMenu {
  cursor: pointer;
  border: 1px solid #cc6328;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 10px;
  position: relative;
  left: -10px;
  display: flex;
  align-items: center;
}

.openMenu:hover {
  left: -2px;
}

.icon {
  width: 60px;
  height: 60px;
}

.icons {
  width: 200px;
  height: 200px;
}

.header h2 {
  color: #cc6328;
  margin-left: 10px;
  margin-bottom: 0;
}

.contentBar {
  background-color: #212153;
  display: flex;
  flex-direction: column;
  flex: 1;
  // display: flex;
  // flex-direction: column; /* Arrange children in a column */
  // height: 100%;
  // background-size: cover;
  // height: 100vh;
  // display: flex;
  // height: 100vh;
}

.userIcon {
  width: 60px;
  height: 60px;
}

.selectedMessage {
  width: 100%;
  background-color: #fbfbfb;
  // height: 100vh;
  height: 100%;
  position: relative;
}

.messageHeader {
  background-color: #ffffff;
  height: 66px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 0.5px rgba(186, 186, 186, 0.13);
}

.selectedUser {
  display: flex;
  align-items: center;
}

.selectedUser h5 {
  margin-left: 5px;
  margin-bottom: 0;
  font-size: 1rem;
}

.user {
  width: 40px;
  height: 40px;
}

.gradientCont {
  display: flex;
}

.gradient {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
}

.gradient1 {
  background: linear-gradient(
    90deg,
    rgba(196, 35, 60, 0.3),
    rgba(0, 165, 162, 0.3)
  );
}

.gradient2 {
  background: linear-gradient(
    90deg,
    rgba(255, 200, 59, 0.3),
    rgba(196, 35, 60, 0.3)
  );
}

.gradient3 {
  background: linear-gradient(
    90deg,
    rgba(204, 99, 40, 0.3),
    rgba(255, 200, 59, 0.3)
  );
}

.textBody {
  padding: 20px;
  // height: calc(100% - 132px);
  height: 800px;
  overflow: auto;
  padding-bottom: 132px;
}

.textBody::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

.textBody::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: #0004;
  visibility: hidden;
}

.textBody:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.chatBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat {
  max-width: 40%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #212153;
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
  margin-bottom: 3px;
}

.chat p {
  margin: 0;
  font-size: 0.8rem;
  padding: 10px;
  line-height: 170%;
}

.chatLeft {
  border-top-right-radius: 10px;
}

.chatRight {
  border-top-left-radius: 10px;
}

.newText {
  background-color: #ffffff;
  height: 66px;
  padding: 0 20px;
  box-shadow: 0 -1px 0.5px rgba(186, 186, 186, 0.13);
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paperPlane {
  transform: rotate(45deg);
}

.newText input {
  margin-right: 10px;
  width: 95%;
  outline: none;
  border: none;
  font-size: 0.9rem;
}

.timestamp p {
  display: flex;
  justify-content: end;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 10px;
}

@media (max-width: 480px) {
  .chat {
    max-width: 50%;
  }
}
