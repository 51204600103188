.legend {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0;
}

.rectangle {
    width: 17px;
    height: 10px;
    background-color: #00A5A2;
    margin-right: 10px;
}

@media (max-width: 480px) {
    .legend {
        margin: 20px 3px 0;
    }

    .rectangle {
        width: 12px;
        height: 8px;
        background-color: #00A5A2;
        margin-right: 5px;
    }
}