.card {
    // background-color: #fff;
    width: 400px;
    border-radius: 10px;
    border: 1px solid;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4rem;
    padding: 30px 0;
}

.name {
    color: #212153;
    font-size: 20px;
}

.score {
    font-weight: bold;
    font-size: 20px;
}

@media (max-width: 1200px) {
    .card {
        width: 100%;
        margin: 1rem;
    }

    .name {
        font-size: 18px;
    }

    .score {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .card {
        width: 100%;
        margin: 4rem 0 0 0;
    }

    .name {
        font-size: 18px;
    }

    .score {
        font-size: 18px;
    }
}