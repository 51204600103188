.ContentQA {
  padding: 20px 0;
}

.title h3 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 30px;
}

.searchBox {
  width: 100%;
  border-bottom: 1px solid #e2e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.searchBox input {
  outline: none;
  border: none;
  padding: 3px;
  width: 90%;
}

.questions {
  margin-top: 20px;
}

.questionBox {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  margin: 15px 0;
  border-radius: 3px;
}

.question {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-right: 20px;
  cursor: pointer;
}

.question p {
  margin: 0;
  font-size: 0.9rem;
}

.filter {
  font-size: 0.9rem;
}

.hidden {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease;
}

.visible {
  opacity: 1;
  max-height: 500px;
  transition: opacity 0.5s ease, max-height 0.5s ease;
}

.answer {
  margin: 0;
  font-size: 0.85rem;
  line-height: 170%;
  padding: 10px;
}

.noQuestions {
  padding: 20px 0;
}

.noQuestions {
  img {
    display: block;
    margin: 20px 0;
  }

  .mobileNoQuestions {
    display: none;
  }

  p {
    font-size: 1.25rem;
    font-family: "quicksand", sans-serif;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .answer {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .question p {
    font-size: 0.8rem;
  }

  .filter {
    font-size: 0.8rem;
  }

  .answer {
    font-size: 0.75rem;
  }
  .noQuestions {
    img {
      display: none;
    }

    .mobileNoQuestions {
      display: block;
    }
  }
}
