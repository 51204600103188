:root {
    --fontColorOne:  #212121;
    --fontFamilyOne: 'quicksand';
    --fontFamilyTwo: 'somoHead';
    --backgroundOne: ' #F5F5F5';
}

.payForm {
    // padding: 36px  40px;
    // border-radius: 15px;
    // background-color: #fff;
    // box-shadow: 8px 15px 30px #0000001A;
    // display: none;
    .info {
        font-family: var(--fontFamilyOne);
        // font-size: 0.85rem;
        margin-bottom: 14px;
        margin-top: 3px;
        min-height: 98px;
        span {
            font-weight: 600;
            color: #cc6328;
        }
    }
    .infoDiv{
        border: 1px solid #dcdcdc;
        margin: 7px 3px 3px;
        border-radius: 4px;
        .infoLbl{
            display: block;
            border: none;
            width: 100%;
            padding: 3px 14px;
            background: #ffffff;
            font-size: 0.9em;
            font-weight: 700;
            color: #cc6328;
            text-align: center;
        }
        .infoInp, .infoInpAmnt{
            display: block;
            border: none;
            width: 100%;
            padding: 7px 14px;
            background: #ffffff;
            color: #000000;
            font: 500 1.1em var(--fontFamilyOne);
            text-align: center;
            margin: auto;
        }
        .infoInpAmnt{
            width: 300px;
            font-size: 3.5em;
            text-align: center;
        }
        .infoInp:focus, .infoInpAmnt:focus{
           outline: none;
        }
    }
    .infoInput{
        border: none;
    }
    .limitError {
        font-family: var(--fontFamilyOne);
        font-size: 0.85rem;
        color: red;
    }
    
}
.pre_ld{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 280px;
}

.rangeslider-horizontal .rangeslider__fill {
    color: #cc6328 !important;
  }