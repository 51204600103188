.buttonsContainer {
  margin-top: 80px;
}

.buttoncontainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 50px;
}

label {
  // font-size: 20px;
  font-weight: bold;
  margin-right: 20px;
}

.button {
  background-color: #cc6328;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 120px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
}

@media (max-width: 480px) {
  .button {
    width: 80px;
    height: 30px;
    font-size: 15px;
    letter-spacing: 1px;
  }
}
