@media print {
  .noPrint {
    display: none;
  }
}

.back {
  font-size: 20px;
  margin: 10px 60px 20px 60px;
  display: none;
}

.back a {
  text-decoration: none;
}

.scoreDetail {
  .noPrint {
    display: block;

    @media print {
      display: none;
    }
  }

  .back {
    font-size: 20px;
  }

  .back a {
    text-decoration: none;
  }
}

@media (max-width: 991px) {
  .back {
    display: block;
  }
}

@media (max-width: 480px) {
  .back {
    font-size: 16px;
    margin: 20px 0 !important;
  }
}
