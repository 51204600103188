:root {
  --red-bg-color: #C4233C;
  --yellow-color: #FFC83B;
  --text-color: #fff;
  --black-color: #000;
  --btn-color: #CC6328;
  --blue-color: #00A5A2;
  --navyblue-color: #212153;
  --report-color: #2d2833;
  --light-red-color: #c47682;
  --grey-color: #737373;
  --light-g-color: #f3f3f3;
  --light-g2-color: #cdcdcd;
  --lighter-color: rgba(50, 50, 50, 0.5);
  --red-color: #ff0000;
}

* {
  transition: 300ms;
  scroll-behavior: smooth;
}

.hide {
  display: none !important;
}

.error {
  padding: 20px;
  margin: 21px auto;
  color: #ff4444;
  background-color: #ffffff;
}

.no_error {
  padding: 20px;
  margin: 21px auto;
  color: #00a5a2;
  background-color: #ffffff;
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  nav {
    Button {
      margin: 9px;
    }
  }

  h2 {
    font-size: 1.7rem;
    color: #cc6328;
    font-weight: 700;
    margin-bottom: 0;
  }

  h3.cohortInfo {
    font-size: 1rem;
    color: #333333;
    font-weight: 500;
    margin-bottom: 0;
  }

  p {
    font-family: "quicksand", sans-serif;
    font-size: 1.1rem;
  }

  .timer {
    transform: scale(0.7);
    position: absolute;
    display: flex;
    left: -12px;
  }
}

div.preSets {
  position: fixed;
  padding: 5%;
  top: 3%;
  right: 3%;
  bottom: 3%;
  left: 3%;
  border-radius: 9px;
  box-shadow: 5px 5px 42px #565656, -5px 5px 42px #565656, -5px -5px 42px #565656, 5px -5px 42px #565656;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  div {
    margin: 15px auto;
    padding: 21px 1%;
    width: 360px;
    max-width: 98%;
    background-color: #efefef;
    border-radius: 5px;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      img {
        width: 120px;
        height: 120px;
        max-width: 100%;
        object-fit: contain;
        margin: 5px auto;
        display: block;
      }

      p {
        padding: 5px 0px;
        margin: 7px 3%;
        text-align: center;
      }

      label {
        display: block;
        margin: 12px 3%;

        select {
          display: block;
          width: 100%;
          padding: 5px;
        }
      }

      button {
        padding: 5px;
        margin: 12px 3%;
        background-color: #cc6328;
        color: #ffffff;
        font-size: 1.1em;
        border: none;
        border-radius: 5px;
        display: block;
        width: 94%;
      }

      span {
        // display: block;
        margin: auto;
        padding: 5px;
        color: #ff4444;
      }

      div.preSetsWait {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ffc83b;
        margin: 0px auto;
        padding: 2% 0px;

        p {
          text-align: left;
        }

        img {
          width: 200px;
          height: 75px;
          max-width: 96%;
          object-fit: contain;
          margin: 0px auto;
          display: block;
        }
      }

    }
  }
}

.AddProductModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modalHeader {
  padding: 1rem;
}

.modalHeader h4 {
  color: #212153;
  font-size: 1.5rem;
}

.modalBody {
  padding: 1.25rem 2rem;
  width: 100%;
}

.modalFooter {
  padding: 0.75rem;
}

.addProductForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.modalBody label {
  font-size: 1rem;
  color: #212153;
}

.items {
  .item {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding: 20px;
    margin: 20px 0;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
    }

    h4 {
      font-size: 1rem;
      color: #00A5A2;
      font-weight: bold;
    }
  }
}

.creditTable {
  margin-top: 40px;
  overflow-x: auto;
  width: 100%;
  border-radius: 15px;
  box-shadow: 8px 15px 30px #0000001a;
  padding: 24px 22px;
  background-color: #ffffff;
  position: relative;
  min-height: 70vh;
  // background: linear-gradient(#ffffff, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.4)), url('https://somoafrica.org/images/eportal/bg1.jpg');
  background-size: cover;
  background-position: left;

  .tableStyles {
    text-align: center;
    overflow-x: auto;
  }

  th {
    color: #606060;
    white-space: nowrap;
  }

  td {
    white-space: nowrap;
  }

  .welcomeAcc {
    margin-top: 25px;
    text-align: center;
    position: relative;
    z-index: 1;

    img {
      // display: none;
      max-width: 100%;
      object-fit: contain;
      max-height: 250px;
    }

    p {
      font-size: 1.1rem !important;
      font-family: "quicksand", sans-serif;
      text-align: left;

      g {
        text-transform: capitalize;
      }
    }

    ol.steps,
    ul.dlinks {
      text-align: left;
    }

    ul.dlinks {
      li {
        a:before {
          content: "Download - ";
          display: inline;
        }
      }
    }

    .surveyList {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 10px 20px;
      border-radius: 5px;
      border: 1px double #00a5a2;
      margin-bottom: 21px;
      max-width: 980px;
      background-color: rgba(255, 255, 255, 0.7);

      .btn {
        margin-top: 5px !important;
      }

      .surveyLanguage {
        width: 98%;
        max-width: 490px;
      }
    }


  }

  canvas.bubblesCanvas {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
  }
}

.applyContainer {
  margin-top: 40px;

  .applyForm {
    // padding: 36px 40px;
    padding: 12px 12px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 8px 15px 30px #0000001a;

    .inputHint {
      font-size: 0.85rem;
      margin-bottom: 0;
      margin-top: 3px;

      span {
        font-weight: 600;
        color: #cc6328;
      }
    }
  }

  input.formInput,
  textarea.formInput,
  select.formInput {
    width: 100%;
    padding: 7px;
  }

  .joinedBullets {
    display: flex;
    // overflow-x: auto;
    justify-content: center;
    margin-top: 40px;
    list-style-type: none;
    padding-inline-start: 0;

    li {
      position: relative;
      text-align: center;
      margin: 0;
      margin-top: -4px;
      margin-right: 10px;
      padding-top: 0.07em;
      padding-bottom: 1.6em;
      font-size: 1.1rem;
      font-family: "quicksand", sans-serif;
      color: #212121;

      &:last-child {
        margin-right: 0;
      }

      &:last-child::before {
        height: 0px;
        margin-left: 0px;
      }

      &:before {
        background-color: #cc6328;
        height: 2px;
        width: calc(100% + 10px);
        content: "";
        position: absolute;
        top: -15px;
        bottom: 0px;
        left: 44px;
      }

      &::after {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23ffffff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        left: calc(50% - 15px);
        top: -30px;
        width: 30px;
        height: 30px;
      }

      &.active {
        color: #cc6328;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23cc6328' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
        }
      }

      b {
        font-weight: normal;

        &:first-of-type {
          display: none;
        }
      }
    }
  }
}

@keyframes bigger {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(2.4);
  }
}

.applicationComplete {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 8px 15px 30px #0000001a;
  margin-top: 40px;
  text-align: center;

  h2 {
    font-weight: 700;
    color: #cc6328;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  p {
    font-size: 0.95rem;
    margin-bottom: 5px;
    font-family: "quicksand", sans-serif;
  }

  div.quizListHold {
    display: flex;
    flex-wrap: wrap;

    div.quizList {
      width: 100%;
      display: flex;
      border: 1px solid #00a5a2;
      padding: 7px;
      border-radius: 7px;
      margin: 10px auto;
      align-items: center;
      transition: all 500ms;
      position: relative;

      &.quizListHide {
        display: none;
      }

      &.quizListoptional {
        border-style: dashed;
      }

      &:hover,
      &.quizListActive {
        transform: scale(1.05);
        box-shadow: 10px 10px 10px #bcbcbc;
      }

      div.quizListInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0px 5px;

        label {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 2px;

          p {
            margin-bottom: 10px;

            g {
              color: #cc6328;
            }
          }

          textarea,
          select,
          input {
            border-radius: 5px;
            display: flex;
            border: 2px solid #dddddd;
            padding: 3px;
            resize: none;
            text-align: center;

            &:focus {
              outline-color: #000000;
              outline-style: double;
            }
          }

          input {
            min-height: 100px;
            padding: 10% 3%;
            width: 100%;
            border: none;
          }
        }

        span {
          border-bottom: 1px dashed #bcbcbc;
        }
      }

      input {
        display: none;
      }
    }

    div.advisorList {
      width: 100%;
      display: flex;
      border: 1px solid #00a5a2;
      padding: 7px;
      border-radius: 7px;
      margin: 10px auto;
      align-items: center;
      transition: all 300ms;
      cursor: pointer;
      position: relative;

      &:hover {
        transform: scale(1.05);
        box-shadow: 10px 10px 10px #bcbcbc;
      }

      .icon {
        font-size: 3em;

        &.iconChecked {
          color: #00a5a2;
        }

        &.iconHidden {
          display: none;
        }

        &.iconCheckedHover {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          animation: bigger 500ms linear forwards;
        }
      }

      .iconLoader {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      div.advisorListInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0px 5px;

        span {
          border-bottom: 1px dashed #bcbcbc;
        }
      }

      input {
        display: none;
      }
    }
  }

  div.quizBtnLdr {
    button.surveySubmitBtn {
      border: 3px double #cc6328;
      padding: 5px 15px;
      border-radius: 5px;
      background-color: #ffffff;
      color: #00a5a2;
      font-size: 1.4em;
      font-weight: 700;
      display: none;
      margin-top: 70px;

      &:focus {
        outline-color: #000000;
        outline-style: double;
      }
    }

    span.surveySubmitLoader {
      display: none;
    }
  }

  nav.playbookNav {
    text-align: left;
    margin: 7px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    Button {
      margin: 5px !important;
    }
  }
}

div.sesInfo {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px 10px;
  box-shadow: 8px 15px 30px #0000001a;
  margin-top: 40px;

  main.sesMain {
    display: flex;
    min-height: 50vh;

    aside.sesAside {
      width: 200px;
      background-color: #efefef;
      padding: 5px;
      display: none !important; //Reserved for a future update
    }

    section.sesSection {
      padding: 5px;
      flex-grow: 1;

      .generalPlaybookContainer {
        width: calc(100%);
        border-bottom-right-radius: 40px;
        border: 3px solid var(--report-color);
        margin-bottom: 20px;
        padding-bottom: 140px;
        box-shadow: 0 3px 3px 3px #737373;
        position: relative;
        overflow: auto;

        .navLinks {
          width: 100%;
          height: 30px;
          display: flex;
          justify-content: center;
          background: var(--report-color);

          a {
            display: inline-block;
            width: calc(100%/3);
            background-color: var(--btn-color);
            text-align: center;
            line-height: 30px;
            // font-weight: bold;
            // font-size: 12px;
            text-decoration: none;
            color: var(--black-color);
            cursor: pointer;

            &.activeLnk {
              color: var(--text-color);
              background: var(--report-color);
              transform: scale(1.1);
            }

            &:nth-child(2) {
              border-right: 3px solid var(--report-color);
              border-left: 3px solid var(--report-color);
            }

            &:last-of-type.activeLnk {
              transform: scale(1);
              font-size: 1.1em;
            }
          }
        }

        div.titleInfo {
          padding: 5px 5px 21px;
          background-color: var(--report-color);
          color: var(--text-color);

          div.ttl {
            display: flex;
            margin-bottom: 14px;

            h3 {
              margin-right: 5px;
              font-size: 1.4em;
            }
          }

          div.info {
            display: flex;
            align-items: center;

            p {
              flex-grow: 1;
            }

            .icon {
              min-width: 100px;
            }
          }
        }

        div.toolsCheckList {
          padding: 5px;
          // display: none;

          div.toolCheck {
            display: flex;
            border-bottom: 1px solid var(--black-color);
            padding: 5px;

            img {
              width: 100px;
              height: 100px;
              margin: 3px;
              object-fit: contain;
              margin-right: 5px;
            }

            div.toolTtl {
              flex-grow: 1;
            }

            div.checkStatus {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
              border: 1px solid var(--report-color);
              border-radius: 10px;
              padding: 5px;
              min-width: 100px;
              box-shadow: 0 3px 3px 3px #9b9b9b;
              margin: 10px auto;

              .icon {
                color: var(--btn-color);
              }
            }
          }
        }

        div.assignments {
          padding: 5px;
          // display: none;

          div.taskList {
            div.task {
              padding: 21px 5px;
              margin: 35px auto;
              border-bottom: 4px double var(--btn-color);

              div.top {
                display: flex;
                flex-wrap: wrap;

                p {
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  border: 1px solid var(--light-g2-color);
                  border-radius: 5px;
                  background-color: var(--light-g-color);
                  margin-right: 5px;
                  margin-left: 5px;

                  &:first-of-type {
                    flex-grow: 2;
                    // margin-right: 10px;
                  }

                  &:last-of-type {
                    flex-grow: 1;
                    // margin-left: 10px;
                  }
                }
              }

              textarea {
                display: block;
                width: 98%;
                min-height: 196px;
                padding: 5px;
                margin-right: auto;
                margin-left: auto;
                border: 1px solid var(--light-g2-color);
                border-radius: 5px;
                background-color: var(--light-g-color);
              }

              div.bottom {
                margin-top: 1rem;
                display: flex;
                justify-content: space-between;

                p.file {
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  margin: 5px 5px 5px 0;
                  border: 1px solid var(--light-g2-color);
                  background-color: var(--light-g-color);
                  border-radius: 5px;
                  min-width: 50%;

                  b {
                    min-width: 91px;
                  }

                  a {
                    margin-right: 15px;
                    text-decoration: underline;
                  }
                }

                button {
                  text-align: center;
                  padding: 5px 21px;
                  margin: 5px 0 5px 5px;
                  border: 1px solid var(--light-g2-color);
                  background-color: var(--btn-color);
                  color: var(--text-color);
                  border-radius: 5px;
                }
              }
            }
          }

          div.replyTask {
            // display: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: var(--lighter-color);

            form {
              max-width: 98%;
              width: 540px;
              margin: 42px auto;
              padding: 21px;
              border-radius: 10px;
              background-color: var(--light-g-color);

              span.close {
                color: var(--red-color);
                border: 1px solid var(--report-color);
                padding: 10px;
                background-color: var(--text-color);
                border-radius: 5px;
                cursor: pointer;
              }


              div.top {
                display: flex;
                flex-wrap: wrap;

                div:first-of-type {
                  flex-grow: 2;
                }

                div:last-of-type {
                  flex-grow: 1;
                }
              }

              div.inputGrp {
                display: flex;
                flex-direction: column;
                margin: 21px 5px;

                input,
                textarea {
                  border-radius: 5px;
                  padding: 5px;
                  border: 1px solid var(--light-g2-color);
                }

                input[readonly] {
                  background-color: var(--light-g-color);
                }
              }

              button {
                text-align: center;
                padding: 7px 21px;
                margin: 21px 5px 21px 42px;
                border: 1px solid var(--light-g2-color);
                background-color: var(--btn-color);
                color: var(--text-color);
                border-radius: 5px;
                align-self: flex-end;
              }
            }
          }



        }

        div.feedbacks {
          div.add {
            button {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              padding: 5px 21px;
              margin: 5px 0 5px 5px;
              border: 1px solid var(--light-g2-color);
              background-color: var(--text-color);
              color: var(--text-color);
              border-radius: 5px;

              .icon {
                color: var(--black-color);
              }

              span {
                padding: 5px 21px;
                margin: 5px 0 5px 5px;
                border: 1px solid var(--light-g2-color);
                background-color: var(--btn-color);
                color: var(--text-color);
                border-radius: 5px;
              }
            }
          }

          div.addFeedback {
            // display: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: var(--lighter-color);

            form {
              max-width: 98%;
              width: 540px;
              margin: 42px auto;
              padding: 21px;
              border-radius: 10px;
              background-color: var(--light-g-color);

              span.close {
                color: var(--red-color);
                border: 1px solid var(--report-color);
                padding: 10px;
                background-color: var(--text-color);
                border-radius: 5px;
                cursor: pointer;
              }


              div.top {
                display: flex;
                flex-wrap: wrap;

                div:first-of-type {
                  flex-grow: 2;
                }

                div:last-of-type {
                  flex-grow: 1;
                }
              }

              div.inputGrp {
                display: flex;
                flex-direction: column;
                margin: 21px 5px;

                input,
                textarea {
                  border-radius: 5px;
                  padding: 5px;
                  border: 1px solid var(--light-g2-color);
                }

                input[readonly] {
                  background-color: var(--light-g-color);
                }

                i.ratingStar {
                  font-size: 3em;
                  padding: 5px;
                  color: var(--btn-color);
                  cursor: pointer;
                }
              }

              button {
                text-align: center;
                padding: 7px 21px;
                margin: 21px 5px 21px 42px;
                border: 1px solid var(--light-g2-color);
                background-color: var(--btn-color);
                color: var(--text-color);
                border-radius: 5px;
                align-self: flex-end;

                span[class="css-0"] {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }

          div.feedList {
            div.feed {
              display: flex;
              padding: 21px 5px;
              margin: 35px 5px;
              border-bottom: 4px double var(--btn-color);

              .icon {
                min-width: 91px;
                margin: 7px 0;
              }

              div.left {
                flex-grow: 1;
                padding: 0 0 0 5px;

                p {
                  align-items: center;
                  padding: 5px;
                  margin: 10px 0;
                  border: 1px solid var(--light-g2-color);
                  background-color: var(--light-g-color);
                  border-radius: 5px;
                  min-width: 50%;

                  b {
                    min-width: 91px;
                  }

                  a {
                    margin-right: 15px;
                    text-decoration: underline;
                  }
                }

                div.msg {
                  display: block;
                  width: 100%;
                  min-height: 98px;
                  padding: 5px;
                  border: 1px solid var(--light-g2-color);
                  border-radius: 5px;
                  background-color: var(--light-g-color);
                }
              }


            }
          }
        }

        // .upperPlaybookContainer,
        // .upperAssignmentContainer {
        //   width: 100%;
        //   height: fit-content;
        //   background: var(--report-color);
        //   padding: 4px 0 20px 0;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress2 .folderContainer2 .reviewContainer .messageTally {
        //   position: relative;
        //   width: 36px;
        //   height: 31px;
        //   border-radius: 40px;
        //   display: grid;
        //   place-items: center;
        //   background-color: var(--yellow-color);

        //   &:last-child {
        //     border-bottom: none;
        //   }
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress2 .folderContainer2 .reviewContainer .messageTally .messageTally2 {
        //   position: absolute;
        //   font-size: 40px;
        //   color: var(--black-color);
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress2 .folderContainer2 .reviewContainer .messageTally .childMessage2 {
        //   position: absolute;
        //   top: -10px;
        //   font-weight: bold;
        // }

        // .feedbackContent {
        //   width: 100%;

        //   p {
        //     margin: 16px 0;
        //   }
        // }

        // .upperPlaybookContainer {

        //   .playbookTitleContainer,
        //   .upperAssignmentContainer .playbookTitleContainer {
        //     width: 100%;
        //     padding-left: 25px;

        //     .playbookTitleContainer {

        //       .playbookTitle,
        //       .upperAssignmentContainer .playbookTitleContainer .playbookTitle {
        //         width: clamp(200px, 90%, 600px);
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-evenly;
        //         color: var(--text-color);
        //         font-weight: bold;
        //       }

        //       .playbookTitleContainer .playbookTitleContent,
        //       .upperAssignmentContainer .playbookTitleContainer .playbookTitleContent {
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         color: var(--text-color);
        //         padding-right: 20px;
        //       }

        //       .playbookTitleContainer .playbookTitleContent p:nth-child(1) {
        //         min-width: 280px;
        //         max-width: 80%;
        //       }
        //     }
        //   }
        // }

        // i.check {
        //   color: var(--text-color);
        //   font-size: 50px;
        //   text-align: center;
        // }

        // .lowerPlaybookContainer {
        //   width: 100%;
        //   padding: 20px;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress,
        // .lowerPlaybookContainer .EntreprenuerProgress2,
        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback {
        //   width: 100%;
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-between;
        //   border-bottom: 1px solid #999999;
        //   padding-bottom: 30px;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback:last-child {
        //   border-bottom: none;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback {
        //   padding: 0px 20px;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback {
        //   padding-bottom: 10px;
        // }


        // .lowerPlaybookContainer .EntreprenuerProgress .folderContainer,
        // .lowerPlaybookContainer .EntreprenuerProgress2 .folderContainer2 {
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: space-between;
        //   width: calc(100% - 300px);
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress img,
        // .lowerPlaybookContainer .EntreprenuerProgress2 img {
        //   width: 90px;
        //   height: 90px;
        //   object-fit: cover;
        //   border-radius: 50%;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback .folderContainerFeedbackProfile {
        //   width: 100%;
        //   height: 60px;
        //   display: flex;
        //   align-items: center;
        //   text-align: center;
        //   letter-spacing: 1px;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback .folderContainerFeedbackProfile p span {
        //   font-weight: bold;
        //   opacity: .5;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback .folderContainerFeedback {

        //   padding: 20px 5px 10px 40px;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback h6 {
        //   margin: 0;
        //   padding: 6px 6px 6px 75px;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback .folderContainerFeedback .paragraph {
        //   padding-left: 75px;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback .folderContainerFeedback .reviewContainerFeedback {
        //   padding-left: 75px;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback .folderContainerFeedback .reviewContainerFeedback a {
        //   display: inline-block;
        //   width: 90px;
        //   text-align: center;
        //   text-decoration: none;
        //   color: var(--black-color);
        //   padding: 5px;
        //   border: 2px solid var(--black-color);
        //   border-radius: 20px;
        //   margin-top: 20px;
        // }

        // .feedbackContent .lowerPlaybookContainerFeedback .EntreprenuerProgressFeedback .folderContainerFeedback .folderContainerFeedbackProfile img {
        //   width: 60px;
        //   height: 60px;
        //   border-radius: 50%;
        //   object-fit: cover;
        //   margin-right: 20px;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress2 .folderContainer2 {
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: space-evenly;
        //   width: calc(100% - 200px);
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress2 .folderContainer2 .reviewContainer {
        //   width: 100%;
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-evenly;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress .folderContainer a {
        //   margin-left: 10px;
        //   font-weight: bold;
        //   color: #00a5a2;
        //   opacity: .7;
        // }

        // .edittingPen {
        //   margin: auto;
        //   color: #CC6328;
        //   cursor: pointer;
        //   font-size: 25px;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress2 .folderContainer2 a {
        //   display: inline-block;
        //   width: 90px;
        //   text-align: center;
        //   text-decoration: none;
        //   color: var(--black-color);
        //   padding: 5px;
        //   border: 2px solid var(--black-color);
        //   border-radius: 20px;
        // }

        // .markAsDoneContainer {
        //   width: 200px;
        //   height: auto;
        //   display: flex;
        //   flex-direction: column;
        //   align-items: center;
        //   justify-content: center;
        //   border-radius: 8px;
        //   margin-top: auto;
        //   padding-bottom: 5px;
        //   box-shadow: 0 0 2px 2px #00000038;
        // }

        // .markAsDoneContainer.hide {
        //   display: none;
        // }

        // .markAsDoneContainer .markAsDone {
        //   width: 50px;
        //   height: 30px;
        //   border-radius: 20px;
        //   cursor: pointer;
        //   display: flex;
        //   padding: 2px 8px;
        //   align-items: center;
        //   border: 1px solid rgba(0, 0, 0, 0.411);
        // }

        // .markAsDoneContainer .markAsDone.markedAsDone .circle {
        //   background-color: #CC6328;
        //   transition: transform .3s linear;
        //   transform: translateX(12px);

        // }

        // .markAsDoneContainer .markAsDone .circle {
        //   width: 20px;
        //   height: 20px;
        //   border-radius: 50%;
        //   background-color: #fff;
        //   box-shadow: 0 0 2px 2px #00000061;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress .completedContainer,
        // .lowerPlaybookContainer .EntreprenuerProgress2 .completedContainer2 {
        //   width: 200px;
        //   height: auto;
        //   display: flex;
        //   flex-direction: column;
        //   align-items: center;
        //   justify-content: center;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress2 .completedContainer2 .completedCircle2.notSubmitted {
        //   background-color: var(--yellow-color);
        //   border: 3px dotted var(--black-color);
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress .completedContainer .completedCircle,
        // .lowerPlaybookContainer .EntreprenuerProgress2 .completedContainer2 .completedCircle2 {
        //   width: 50px;
        //   height: 50px;
        //   border-radius: 50%;
        //   display: grid;
        //   place-items: center;
        //   background-color: #999999;
        //   border: 3px solid var(--black-color);
        // }

        // .completedContainer2 :nth-child(1) {
        //   opacity: .6;
        // }

        // .completedContainer2 p {
        //   font-weight: bold;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress .completedContainer .completedCircle i {
        //   font-weight: 900;
        //   font-size: 40px;
        // }

        // .lowerPlaybookContainer .entrepreneursListContainer {
        //   width: 100%;
        //   height: fit-content;
        //   padding-top: 20px;
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-evenly;
        //   flex-wrap: wrap;
        // }

        // .lowerPlaybookContainer .entrepreneursListContainer .entrepreneur {
        //   width: 120px;
        //   height: auto;
        //   display: flex;
        //   flex-direction: column;
        //   align-items: center;
        //   justify-content: space-evenly;
        //   cursor: pointer;
        //   margin-bottom: 15px;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress .completedContainer {
        //   display: none;
        // }

        // .lowerPlaybookContainer .EntreprenuerProgress .completedContainer.visible {
        //   display: flex;
        // }

        // .lowerPlaybookContainer .entrepreneursListContainer .entrepreneur:hover {
        //   border: 1px solid var(--report-color);
        //   border-radius: 4px;
        // }

        // .lowerPlaybookContainer .entrepreneursListContainer .entrepreneur img:hover {
        //   filter: grayscale(0%);
        // }

        // .lowerPlaybookContainer .entrepreneursListContainer .entrepreneur img {
        //   width: 80px;
        //   height: 80px;
        //   border-radius: 50%;
        //   border: 1px solid #9999;
        //   object-fit: cover;
        //   filter: grayscale(100%);
        // }

        // .noChapterError {
        //   width: 100%;
        //   font-weight: bold;
        //   font-size: 12px;
        //   color: var(--btn-color);
        //   text-align: center;
        // }

        // .bellContainer {
        //   cursor: pointer;
        // }

        // .sessionContainer {
        //   width: 95%;
        //   margin: auto;
        //   margin-left: 10px;
        //   margin-top: 8px;
        //   background-color: rgba(128, 128, 128, 0.338);
        //   border-radius: 1px;
        //   padding: 2px;
        //   color: #000;
        //   display: none;
        // }
      }

    }
  }
}

.continueModal {
  padding: 15px;

  h3 {
    font-size: 1.3rem;
  }

  p {
    font-family: "quicksand", sans-serif;
    font-size: 1.15rem;
  }
}

@media only screen and (max-width: 740px) {
  .heading {
    nav {
      Button {
        margin: 9px 2px;

        span.btnTxt {
          display: none;
        }
      }
    }
  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      font-size: 2rem;
      margin-bottom: 8px;
    }
  }

  .applyContainer {
    margin-top: 40px;

    .applyForm {
      padding: 36px 40px;
      border-radius: 15px;
      background-color: #fff;
      box-shadow: 8px 15px 30px #0000001a;

      .inputHint {
        font-size: 0.85rem;
        margin-bottom: 0;
        margin-top: 3px;

        span {
          font-weight: 600;
          color: #cc6328;
        }
      }
    }

    .joinedBullets {
      display: flex;
      // overflow-x: auto;
      margin-top: 40px;
      list-style-type: none;
      padding-inline-start: 0;

      li {
        position: relative;
        text-align: center;
        margin: 0;
        margin-top: -4px;
        margin-right: 10px;
        padding-top: 0.07em;
        padding-bottom: 1.6em;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        color: #212121;

        &:last-child {
          margin-right: 0;
        }

        &:last-child::before {
          height: 0px;
          margin-left: 0px;
        }

        &:before {
          background-color: #cc6328;
          height: 2px;
          width: calc(100% + 10px);
          content: "";
          position: absolute;
          top: -15px;
          bottom: 0px;
          left: 50px;
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23ffffff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
          left: calc(50% - 15px);
          top: -30px;
          width: 30px;
          height: 30px;
        }

        &.active {
          color: #cc6328;

          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23cc6328' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  .applicationComplete {
    width: 550px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    margin-top: 60px;
    text-align: center;

    h2 {
      font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }

    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }

  .continueModal {
    padding: 15px;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.15rem;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      font-size: 2rem;
      color: #cc6328;
      font-weight: 700;
    }

    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.1rem;
    }
  }

  .creditTable {
    margin-top: 40px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 8px 15px 30px #0000001a;
    padding: 24px 22px;
    background-color: #ffffff;

    .tableStyles {
      text-align: center;
    }

    th {
      color: #606060;
    }

    .welcomeAcc {
      margin-top: 25px;
      text-align: center;

      img {
        display: block;
        margin: 0 auto;
      }

      .mobileNoLoan {
        display: none;
      }

      p {
        font-size: 1.25rem;
        font-family: "quicksand", sans-serif;
      }

    }
  }

  .applyContainer {
    margin-top: 40px;

    .applyForm {
      padding: 36px 40px;
      border-radius: 15px;
      background-color: #fff;
      box-shadow: 8px 15px 30px #0000001a;

      .inputHint {
        font-size: 0.85rem;
        margin-bottom: 0;
        margin-top: 3px;

        span {
          font-weight: 600;
          color: #cc6328;
        }
      }
    }

    .joinedBullets {
      display: block;
      margin-top: 40px;
      list-style-type: none;
      padding-inline-start: 0;

      li {
        text-align: left;
        position: relative;
        margin: 0;
        margin-top: -4px;
        padding-top: 0.07em;
        padding-bottom: 1.6em;
        padding-left: 40px;
        font-size: 1.1rem;
        font-family: "quicksand", sans-serif;
        color: #212121;

        &:last-child::before {
          height: 0px;
        }

        &:before {
          background-color: #cc6328;
          width: 2px;
          height: 100%;
          content: "";
          position: absolute;
          top: 25px;
          bottom: 0px;
          left: 14px;
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23ffffff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
          left: 0;
          top: 0px;
          width: 30px;
          height: 30px;
        }

        &.active {
          color: #cc6328;

          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='%23741763' fill='%23cc6328' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
          }
        }

        b {
          font-weight: normal;

          &:first-of-type {
            display: initial;
          }
        }

        display: flex;

        b {
          font-weight: normal;

          &:last-of-type {
            display: none;
            order: -1;
            padding-right: 7px;
          }
        }
      }
    }
  }

  .applicationComplete {
    width: 550px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px 60px;
    box-shadow: 8px 15px 30px #0000001a;
    margin-top: 60px;
    text-align: center;

    h2 {
      font-weight: 700;
      color: #cc6328;
      margin-top: 10px;
      margin-bottom: 25px;
    }

    p {
      font-size: 0.95rem;
      margin-bottom: 5px;
      font-family: "quicksand", sans-serif;
    }
  }

  .continueModal {
    padding: 15px;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.15rem;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}