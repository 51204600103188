.container {
    background-color: #F5F5F5;
    margin: 0 auto;
    text-align: center;
    padding-top: 40px;
    min-height: 100vh;
    h1 {
        font-size: 36px;
        font-weight: 700;
        color: #cc6328;
    }
}

.formBox {
    background-color: #FFFFFF;
    padding: 40px 60px 20px;
    border-radius: 15px;
    margin: 0 auto;
    width: 500px;
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 8px 15px 30px #0000001A;
}