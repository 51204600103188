.onboardPassportForm {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 70px;
  padding: 12px;
  box-shadow: 8px 15px 30px #0000001a;
  h3 {
    font-size: 1.65rem;
    font-weight: 600;
    font-family: "quicksand", sans-serif;
  }
  .validID {
    font-size: 0.85rem;
    margin-bottom: 25px;
    font-family: "quicksand", sans-serif;
  }
  .passportVerify {
    margin-top: 20px;
    form{
      .formInput{
        display: block;
        margin: 25px auto;
        padding: 7px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #999999;
      }
      img.passport{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px auto;
        width: 400px;
        height: 200px;
        max-width: 100%;
        object-fit: contain;
        background-color: #f5f5f5;
        border: 1px dashed #565656;
        border-radius: 5px;
      }
    }
  }
  .info {
    font-family: "quicksand", sans-serif;
    font-size: 1em;
    // .downloadLink{
    //   display: block;
    //   padding: 14px;
    //   margin: 14px auto;
    //   border: 1px solid #909090;
    // }
  }
  .extraTip {
    font-size: 0.8rem;
    font-family: "quicksand", sans-serif;
    margin-top: 10px;
    margin-bottom: 0;
    span {
      font-weight: 600;
      color: #cc6328;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .onboardPassportForm {
    width: 550px;
    padding: 40px 60px;
    h3 {
      font-size: 1.75rem;
    }
    .passportVerify {
      margin-top: 25px;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
