.analyticsCont {
  margin: 20px 0;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0.4rem 0.8rem #0005;
  border-radius: 5px;
}

.selectCont {
  display: flex;
  margin: 20px 0;
}

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32"%3E%3Cpath d="M0 6h32v20H0z" fill="%23334" /%3E%3Cpath d="M10 16l6-6 6 6z" fill="#FFF" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 12px 8px;
  padding-right: 2rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  height: 38px;
  width: 230px;
  //   max-width: 90%;
  z-index: 1;
  margin-right: 20px;
}

.select:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(255, 200, 59, 0.5);
}

.formControl {
  display: block;
  //width: 230px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.analytics {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.analytic {
  margin: 20px 0;
  width: 100%;
  //   flex-grow: 1;
  //   background-color: #ffffff;
  //   padding: 10px;
  //   border-radius: 15px;
  //   height: 350px;
}

label {
  color: #212153;
  font-size: 14px;
}

.loading {
  height: 300px;
  width: 100%;
  background: #eeeeee79;
  border-radius: 10px;
  margin-bottom: 20px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #eeeeee79;
  }
  50% {
    background-color: #ececec;
  }
  100% {
    background-color: #eeeeee79;
  }
}

.noQuestions {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  margin-top: 40px;
}

.errorQuestions {
  height: 300px;
  width: 100%;
  background: #f7f7f7;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .analytics {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 560px) {
  .selectCont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .selectCont div {
    width: 50%;
  }

  .select {
    width: 90%;
    margin-right: 0;
  }
}
