.header {
    width: 100%;
    padding: 20px;
    background-color: #FFC83B;
    color: #C4233C;
}

.piechart {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    width: 100%;
}

// .piechart {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-top: 64px;
// }

// .pieChartContainer {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     min-width: 50%;
// }

// .scoresPieChart {
//     width: 75%;
//     height: 240px;
// }

.legend {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 30px;
}

.pieChartContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50%;
}

.scoreContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scoreContainer .description {
    color: #00A5A2;
    font-size: 3rem;
    margin-top: 20px;
}

.metricsContainer {
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
}

.metric {
    // width: 220px;
    // width: calc(90% / 3);
    flex: 1 1 300px;
    box-shadow: 5px 5px 10px #7d7d7d;
    // box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    // border: 3px solid #000000;
    padding: 20px;
    border-radius: 10px;
    // height: 300px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    cursor: pointer;
}

.metric .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 40px;
}

.top .score {
    font-size: 1.1875rem;
    // font-weight: bold;
    color: #212121;
}

.metric p {
    margin: 0;
}

.metric .name {
    font-size: 1.0625rem;
    font-weight: bold;
    color: #212121;
    margin: 30px 0;
}

.icon {
    display: flex;
    justify-content: flex-end;
}

.openMetric {
    // transform: rotate(45deg);
    cursor: pointer;
}

.scorecardText {
    margin: 40px auto;
    width: 90%;
    text-align: center;
    line-height: 170%;
    font-size: 1.2rem;
}




@media (min-width: 992px) and (max-width: 1199.98px) {
    .piechart {
        flex-direction: column-reverse;
    }

    .scoreContainer {
        margin-bottom: 40px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .scoreContainer {
        width: 40%;
        padding: 20px;
    }

    .scoreContainer h3 {
        font-size: 1.3rem;
    }

    .scoreContainer .description {
        font-size: 2rem;
    }
}

@media (max-width: 767.98px) {
    .header h2 {
        font-size: 1.3rem;
    }

    .piechart {
        flex-direction: column-reverse;
    }

    .scoreContainer {
        margin-bottom: 40px;
    }

    .scoreContainer h3 {
        font-size: 1.3rem;
    }

    .scoreContainer .description {
        font-size: 2rem;
    }
}


/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    /* Styles for phones */
}