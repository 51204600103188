.uploadBtn {
  font-size: 1.05rem;
  box-shadow: 5px 10px 20px #00000017;
  border-radius: 5px;
  padding: 8px 16px;
  border: 0.2px solid #707070;
  cursor: pointer;
  text-align: center;
}

.fullwidth {
  width: 100%;
}

.label {
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-family: "quicksand", sans-serif;
}

.checkIcon {
  margin-left: 20px;
}
