// .lmsNotes {
//   width: 80%;
// }

.topBar {
  // transition: all 300s;
  padding: 30px 20px 20px 0;
  border-bottom: 1px solid #e2e7eb;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
}

.openMenu {
  cursor: pointer;
  border: 1px solid #cc6328;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 10px;
  position: relative;
  left: -10px;
  display: flex;
  align-items: center;
}

.openMenu:hover {
  left: -2px;
}

.icon {
  width: 60px;
  height: 60px;
}

.header h2 {
  color: #cc6328;
  margin-left: 10px;
  margin-bottom: 0;
}

.searchNotes {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchContainer {
  border-bottom: 1px solid #e2e7eb;
}

.searchContainer input {
  margin-right: 10px;
  width: 230px;
  outline: none;
  border: none;
}

.addIcon {
  cursor: pointer;
}

.notes {
  padding: 20px;
  display: flex;
  flex-wrap: wrap; /* This allows the flex items to wrap */
  gap: 5px; /* Optional: Adds space between the items */
  justify-content: space-around;
  // grid-template-columns: ;
  // display: flex;
  // flex-wrap: wrap;
}

.noteCont {
  width: 80%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 8px 15px 30px #0000001a;
  margin-top: 40px;
}

.notesHeader {
  padding: 30px 30px 20px;
  border-bottom: 1px solid #e2e7eb;
}

.notesBody {
  padding: 20px 30px 30px;
}

.notesBody ul {
  margin-bottom: 20px;
}

.title {
  font-size: 1.5rem;
  margin-left: 5px !important;
}

.notesHeader {
  display: flex;
  justify-content: space-between;
}

.noteIcon {
  width: 40px;
  height: 40px;
}

.notesBody p {
  font-size: 0.95rem;
  line-height: 170%;
  padding-bottom: 20px;
  margin-bottom: 0;
}

.download {
  background-color: #00a5a2;
  padding: 3px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.AddModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modalHeader {
  padding: 1rem;
}

.modalHeader h4 {
  color: #212153;
  font-size: 1.5rem;
}

.modalBody {
  padding: 1.25rem 2rem;
  width: 100%;
}

.modalFooter {
  padding: 0.75rem;
}

.addProductForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.modalBody label {
  font-size: 1rem;
  color: #212153;
}

.modalBody input {
  width: 100%;
  outline: none;
  border: none;
}

.modalBody textarea {
  width: 100%;
  margin-top: 20px;
  outline: none;
  border: none;
  min-height: 30px;
}

.addHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addHeaderIcon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

ul {
  padding-left: 20px;
  margin-bottom: 0;
}

ul li {
  font-size: 0.9rem;
}

.newNote {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.noNotes {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 80px auto;
}

.noNotes h2 {
  font-size: 20px;
  margin-top: 20px;
}

.loader {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px auto;
}

.editButton {
  font-family: "quicksand", sans-serif;
  font-size: 1.15rem;
  font-weight: 700;
  border-radius: 4px;
  border: none;
  color: #fff;
  background-color: #00a5a2;
  padding: 12px 28px;

  &:focus {
    outline: none;
  }
}

.noQuestions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.errorQuestions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px auto;
}
