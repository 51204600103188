.progressBar {
  // width: 700px;
  .circle {
    position: relative;
    display: inline-block;
    width: 80px;
    .activeStageIcon {
      width: 38px;
      height: 38px;
      margin: 0 auto;
      border-radius: 100%;
      background-color: #cc6328;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .stageIcon {
      width: 38px;
      height: 38px;
      margin: 0 auto;
      border-radius: 100%;
      background-color: #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      text-align: center;
      display: inline-block;
      margin-top: 5px;
      font-family: "quicksand", sans-serif;
      font-weight: 600;
      color: #212121;
    }
    .activeTitle {
      text-align: center;
      display: inline-block;
      margin-top: 5px;
      font-family: "quicksand", sans-serif;
      font-weight: 600;
      color: #cc6328;
    }
  }
  .bar {
    position: relative;
    width: 50px;
    height: 2px;
    background-color: #cc6328;
    opacity: 0.4;
    display: inline-block;
    top: -33px - 5px;
    margin-left: -20px;
    margin-right: -20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .progressBar {
    .circle {
      width: 80px;
      .activeStageIcon {
        width: 40px;
        height: 40px;
      }
      .stageIcon {
        width: 40px;
        height: 40px;
      }
    }
    .bar {
      position: relative;
      width: 100px;
      height: 2px;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
