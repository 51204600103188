.bizInfo {
    margin: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .biz {
        display: flex;
        align-items: center;
        // margin-right: 80px;

        h5 {
            color: #C4233C;
            margin-right: 5px;
            margin-bottom: 0;
            font-size: 1.2rem;
        }

        .bizAns {
            margin: 0;
            color: #212529;
        }
    }
}

.back {
    margin: 40px 20px;
}

.metricTop {
    background-color: #C4233C;
    padding: 20px 30px;
    margin-top: 80px;

    .nextIcons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0;

        h3 {
            color: #ffffff;
            font-weight: bold;
            margin: 0;
        }

        .icon {
            padding-bottom: 60px;
            width: 10%;
            cursor: pointer;
        }

        .name {
            padding-bottom: 60px;
            border-bottom: 10px dotted #FFC83B;
            width: 100%;
            text-align: center;
        }
    }

    .score {
        color: #FFC83B;
        text-align: center;
    }

    .description {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px;
    }

    .desc {
        font-size: 1.2rem;
        line-height: 170%;
        margin-left: 40px;
    }

    .description p {
        color: #ffffff;
    }
}

.metrics {
    margin: 80px 0;

    .metric {
        border: 3px solid #000000;
        padding: 20px;
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        p {
            font-weight: bold;
            font-size: 1.2rem;
            margin: 0;
        }
    }

    .metricDescription {
        .metricDesc {

            p {
                margin: 0;
                padding: 0;
                font-size: 1rem;
                margin-bottom: 60px;
                color: #000000;
            }
        }

        .metricChart {

            .pie {
                width: 50%;
                text-align: center;
            }
        }

        .metricLink {
            margin: 60px 15px 0 0;
        }
    }

}

.halfDoughnut {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

}

.legend {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    margin-left: 100px;
}


.hide {
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
}

.show {
    height: auto;
    opacity: 1;
    padding: 20px;
    transition: height 0.8s ease, opacity 0.8s ease;
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .bizInfo {
        margin: 80px 0;

        .biz {
            h5 {
                font-size: 1rem;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .bizInfo {
        margin: 80px 0;

        .biz {
            h5 {
                font-size: 1rem;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .bizInfo {
        margin: 30px 20px 0;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: flex-start;

        .biz {
            margin: 20px 0;

            h5 {
                font-size: 1rem;
            }
        }
    }


    .metricTop {
        background-color: #C4233C;
        padding: 20px 30px;
        margin-top: 30px;

        .nextIcons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 40px 0;

            h3 {
                font-size: 1.2rem;
            }

            .icon {
                padding-bottom: 60px;
                width: 20%;
                cursor: pointer;
            }

            .name {
                padding-bottom: 60px;
                border-bottom: 10px dotted #FFC83B;
                width: 60%;
            }
        }

        .score {
            font-size: 1.2rem;
        }

        .description {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 40px 0;
            flex-direction: column;
        }

        .desc {
            font-size: 1.2rem;
            margin-left: 0;
            margin-top: 20px;
            text-align: center;
        }
    }

    .metrics {
        margin: 80px 0;

        .metric {
            p {
                font-size: 1rem;
                margin: 0 10px 0 0;
            }
        }


        .metricDescription {
            .metricChart {
                .pie {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .halfDoughnut {
        display: flex;
        flex-direction: column-reverse;
        align-items: baseline;
        justify-content: center;
    }

    .legend {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

}


@media (max-width: 575.98px) {}