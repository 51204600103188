.footerSection {
    padding: 100px 30px;
    background-color: #1e2144;
    text-align: center;
    .container {
        margin: 0 auto;
        max-width: 1200px;
        img {
            width: 200px;
        }
        .socialLinks {
            margin-top: 40px;
            a {
                color: #fff;
                margin: 0 14px;
                font-size: 1.8em;
            }
        }
        .footerLinks {
            margin-top: 30px;
            ul {
                display: flex;
                justify-content: center;
                list-style-type: none;
                padding-inline-start: 0;
                li {
                    padding: 0 25px;
                    &:first-child {
                        border-right: 1px solid #fff;
                    }
                    a {
                        color: #fff;
                        font-family: 'quicksand', sans-serif;
                        font-size: 1.1rem;
                    }
                }
            }
        }
        .copyright {
            margin-top: 30px;
            font-family: 'quicksand', sans-serif;
            font-size: 1rem;
            opacity: 0.8;
            color: #fff;
        }
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .footerSection {
        padding: 80px 100px;
        background-color: #1e2144;
        text-align: center;
        .container {
            margin: 0 auto;
            max-width: 1200px;
            img {
                width: 200px;
            }
            .socialLinks {
                margin-top: 40px;
                a {
                    color: #fff;
                    margin: 0 14px;
                }
            }
            .footerLinks {
                margin-top: 30px;
                ul {
                    display: flex;
                    justify-content: center;
                    list-style-type: none;
                    padding-inline-start: 0;
                    li {
                        padding: 0 25px;
                        &:first-child {
                            border-right: 1px solid #fff;
                        }
                        a {
                            color: #fff;
                            font-family: 'quicksand', sans-serif;
                            font-size: 1.1rem;
                        }
                    }
                }
            }
            .copyright {
                margin-top: 30px;
                font-family: 'quicksand', sans-serif;
                font-size: 1rem;
                opacity: 0.8;
                color: #fff;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}