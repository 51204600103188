.container {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 3px solid #000000;
    background-color: #FFC83B;
}

.cont {
    height: 100%;
    border-radius: 20px;
    background-color: #C4233C;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.completed {
    margin: 30px 0;
    font-weight: bold;
}

.tableCont {
    margin: 40px 0;
}

.tableBody {
    width: 100%;
    margin: 1.6rem auto;
    margin-top: 0.8rem;
    border-radius: 0.6rem;
    overflow: auto;
}

.licenseTable {
    width: 100%;
}

.licenseTable th {
    position: sticky;
    top: 0;
    color: #C4233C;
    font-size: 1.1rem;
    background-color: #fff;

}

.licenseTable tbody tr {
    border-bottom: 1px solid #e2e7eb;
}

.licenseTable td {
    font-size: 0.9rem;
}