.creditTable {
  overflow-x: auto;
  width: 100%;
  border-radius: 15px;
  box-shadow: 8px 15px 30px #0000001a;
  padding: 24px 22px;
  background-color: #ffffff;
  position: relative;
  min-height: 70vh;

  .tableStyles {
    text-align: center;
    overflow-x: auto;
  }

  th {
    color: #606060;
    white-space: nowrap;
  }

  td {
    white-space: nowrap;
  }

  .welcomeAcc {
    margin-top: 25px;
    position: relative;
    z-index: 1;

    img {
      max-width: 100%;
      object-fit: contain;
      max-height: 250px;
    }

    p {
      font-size: 1.1rem !important;
      font-family: "quicksand", sans-serif;
      text-align: left;

      g {
        text-transform: capitalize;
      }
    }

    ol.steps,
    ul.dlinks {
      text-align: left;
    }

    ul.dlinks {
      li {
        a:before {
          content: "Download - ";
          display: inline;
        }
      }
    }

    .surveyList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // flex-direction: column;
      // align-items: flex-start;
      padding: 10px 10px 20px;
      border-radius: 5px;
      border: 1px double #00a5a2;
      margin-bottom: 21px;
      max-width: 980px;
      background-color: rgba(255, 255, 255, 0.7);

      .surveyName {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      .btn {
        margin-top: 5px !important;
      }

      .surveyLanguage {
        width: 98%;
        max-width: 490px;
      }

      .button {
        margin-top: 40px;
        background-color: #cc6328;
        color: #ffffff;
        padding: 8px 28px;
        font-family: "quicksand", sans-serif;
        font-size: 1.15rem;
        font-weight: 700;
        border-radius: 4px;
        border: none;
        &:focus {
          outline: none;
        }
        &:disabled,
        &[disabled] {
          opacity: 0.7;
        }
      }
    }
  }

  canvas.bubblesCanvas {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .creditTable {
    // margin-top: 40px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 8px 15px 30px #0000001a;
    padding: 24px 22px;
    background-color: #ffffff;

    .tableStyles {
      text-align: center;
    }

    th {
      color: #606060;
    }

    .welcomeAcc h2 {
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 25px;
    }
  }
}

@media (max-width: 480px) {
  .welcomeAcc {
    h2 {
      font-size: 1rem;
      font-weight: 700;
    }

    p {
      font-size: 1rem;
    }
  }

  .surveyList {
    .btn {
      margin-top: 5px !important;
    }

    .icon {
      font-size: 0.5em;
    }

    .surveyTitle {
      font-size: 1rem;
      margin-bottom: 0;
    }

    .button {
      padding: 8px 15px !important;
      font-size: 1rem !important;
      margin-top: 20px !important;
    }
  }
}
