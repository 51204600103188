.bvnFormBox {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  margin-top: 60px;
  margin-bottom: 70px;
  padding: 30px 30px;
  box-shadow: 8px 15px 30px #0000001a;

  p {
    font-family: "quicksand", sans-serif;
    font-size: 1.15rem;
    margin-bottom: 35px;
    text-align: left;

    li {
      margin-left: 21px;
    }
  }

  .extraTip {
    font-family: "quicksand", sans-serif;
    margin-top: 5px;
    font-size: 0.85rem;

    span {
      font-weight: 600;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bvnFormBox {
    width: 500px;
    padding: 40px 60px;

    p {
      font-family: "quicksand", sans-serif;
      font-size: 1.2rem;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}