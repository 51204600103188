:root {
    --fontColorOne:  #212121;
    --fontFamilyOne: 'quicksand';
    --fontFamilyTwo: 'somoHead';
    --backgroundOne: ' #F5F5F5';
}

.welcomeGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 700;
    }
    p {
        font-family: var(--fontFamilyOne);
        font-size: 1.05rem;
        margin-bottom: 0;
    }
}


.loansTable {
    margin-top: 50px;
    .filterInput {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
            margin-bottom: 0;
            font-size: 1.2rem;
            font-family: var(--fontFamilyOne);
            font-weight: 600;
            margin-right: 20px;
        }
        .inputWrapper {
            width: 180px;
        }
    }
    .cardTable {
        margin-top: 30px;
        background-color: #fff;
        border-radius: 10px;
        padding: 24px 24px;
        box-shadow: 8px 15px 30px #0000001A;
        table {
            thead {
                background-color: #cc6328;
                color: #fff;
            }
            .loanId {
                color: #0075D7;
                font-weight: 600;
            }
        }
        .nullList {
            text-align: center;
            width: 100%;
            padding: 20px 0;
        }
        .tableFooter {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .rowsInput {
                p {
                    margin-bottom: 0;
                    margin-right: 14px;
                }
                select {
                    background-color: #fff;
                    border: 1px solid #dee2e6;
                    border-radius: 0.25rem;
                    padding: 7px 14px;
                    width: 70px;
                    -webkit-appearance: none; 
                    -moz-appearance: none; 
                    appearance: none; 
                    background: #F0F0F0 url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='12' height='12' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat;
                    background-position: right 15px top 50%;
                    &:focus {
                        outline: none;
                    }
                }
                display: flex;
                align-items: center;
            }
            .pagination {
                margin-left: 30px;
                justify-content: flex-end;
                margin-bottom: 0;
                .active {
                    span {
                        background-color: #cc6328 !important;
                        border: 1px solid #cc6328;
                    }
                }
                a {
                    color: #000;
                }
            }
        }
    }
}