.searchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

.selectContainer {
    margin: 0 15px;
    width: 25%;
}

.selectContainer label {
    color: #CC6328;
    font-weight: bold;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32"%3E%3Cpath d="M0 6h32v20H0z" fill="%23334" /%3E%3Cpath d="M10 16l6-6 6 6z" fill="#FFF" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 12px 8px;
    padding-right: 2rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #495057;
    font-size: 1rem;
    line-height: 1.5;
    height: 38px;
    width: 100%;
    max-width: 100%;
}

select:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.formControl {
    display: block;
    width: 230px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.buttonContainer {
    margin-top: 26px;
    width: 25%;
}

.button {
    background-color: #FFC83B;
    color: #CC6328;
    margin-top: 26px;
    border: none;
    border-radius: 5px;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 0 20px;
}


@media (max-width: 700px) {
    .searchContainer {
        flex-direction: column;
    }

    .formControl {
        width: 50%;
    }

    .selectContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
    }

    .selectContainer label {
        font-size: 15px;
    }

    .year {
        margin-left: 20px;
    }

    .buttonContainer {
        margin-top: 26px;
        width: auto;
    }
}

@media (max-width: 310px) {
    .selectContainer label {
        font-size: 13px;
    }

    select {
        font-size: 13px;
    }

    .formControl {
        padding: 5px;
        font-size: 13px;
        font-weight: 400;
    }
}