.cardTable {
    margin-top: 50px;
}

.tableContainer {
    overflow: hidden;
}

.table {
    overflow: auto;
}

table,
th,
td {
    padding: 0.8rem;
    border-collapse: collapse;
    text-align: left;
}

thead th {
    background-color: #fff;
}


.headingBox {
    vertical-align: text-bottom;
    background-color: #FFC83B;
    margin: 40px 0 0 0;
    padding: 10px 50px 3px 50px;
}

.headingBox h4 {
    color: #CC6328;
}

th {
    font-size: 20px;
}

@media (max-width: 480px) {
    .headingBox {
        margin: 10px 0 0 0;
        padding: 10px 10px 3px 10px;
    }

    .headingBox h4 {
        font-size: 16px;
    }
}