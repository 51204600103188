.orgInfo {
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 0 0;
    margin: 10px 40px 0;
}

.somoInfo {
    padding: 0 50px;
    color: #CC6328;
}

.somoInfo h1 {
    font-size: 30px;
    font-weight: bolder;
}

.somoInfo p {
    max-width: 250px;
    margin-top: 20px;
}

.headingBox {
    vertical-align: text-bottom;
    background-color: #FFC83B;
    margin: 20px 0 0 0;
    padding: 10px 50px 3px 50px;
}

.headingBox h4 {
    color: #CC6328;
    text-transform: uppercase;
}

.entrepreneurInfo {
    padding: 20px 50px 0;
}

.info {
    display: flex;
    align-items: start;
    justify-content: start;
    font-size: 13px;
}

.infoLabel {
    padding: 12px;
    width: 50%;
    text-transform: uppercase;
}

.infoData {
    width: 50%;
    padding: 12px;
}

@media (max-width: 480px) {
    .somoInfo {
        padding: 0 20px;
    }

    .somoInfo h1 {
        font-size: 24px;
    }

    .somoInfo p {
        font-size: 15px;
    }

    .headingBox {
        margin: 10px 0 0 0;
        padding: 10px 10px 3px 10px;
    }

    .headingBox h4 {
        font-size: 16px;
        text-transform: none;
    }

    .infoTable td {
        padding: 12px 3px !important;
        font-size: 10px;
        max-width: 30px;
    }

    .entrepreneurInfo {
        padding: 20px 5px 0;
    }

    .orgInfo {
        padding: 40px 0 0;
        margin: 0 0 0;
    }

    .info {
        font-size: 12px;
    }

    .infoLabel {
        padding: 12px 3px;
        text-transform: none;
    }

    .infoData {
        padding: 12px 3px;
    }
}

@media print {
    .noPrint {
        display: none;
    }
}