.programDetails {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.3333333333333333);
  min-height: 100vh;
  margin-top: 20px;
}

.infoCont {
  padding: 10px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.program {
  font-size: 18px;
  padding: 20px;
}
.courseContent {
  display: flex;
}

.leftPanel {
  border-right: 1px solid #e2e7eb;
  overflow: hidden;
}

.leftPanelCont {
  padding: 0 20px 20px;
}

.closeCont {
  position: relative;
  height: 50px;
}

.closeBox {
  margin-top: 20px;
  border: 2px solid #c4233c;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 20px;
}

.openContent {
  margin: 0 0 20px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.openContent h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #ffc83b;
  // background-color: #00a5a2;
  border-radius: 50%;
  margin-left: 3px;
}

.menu {
  border-left: 2px solid #ffffff;
}

.menu:hover {
  background-color: #e9e9e9;
}

.courses {
  margin-left: 20px;
  margin-bottom: 20px;
}

.class {
  padding: 10px 0;
  cursor: pointer;
}

.classTitle {
  display: flex;
  align-items: center;
}

.classTitle h5 {
  font-size: 11px;
  font-weight: 700;
  margin: 5px;
  line-height: 170%;
}

.courseName {
  padding-right: 30px;
}

.chapterTitle {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.chapters {
  // border-left: 2px dotted #00a5a2;
  // margin: 10px 0 10px 10px;
}

.topics {
  border-left: 2px dotted #00a5a2;
  margin: 10px 0 10px 10px;
}

.topic {
  margin: 10px 0 10px 10px;
  // width: calc(100% - 10px);
}

.topic {
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  border-bottom: 1px dotted #878787;
  padding-left: 10px;
  margin-left: 10px;
}

.topic h5 {
  font-size: 12px;
  color: #878787;
  margin: 5px;
  line-height: 170%;
}

.loader {
  padding-left: 20px;
}

.chevron {
  position: absolute;
  right: 3px;
}

.inProgress {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  border: 2px solid #00a5a2;
  background-color: #ffc83b;
  display: flex;
  justify-content: center;
  align-items: center;
}

// @media (min-width: 992px) and (max-width: 1199px) {
// }

// @media (min-width: 768px) and (max-width: 991px) {
// }

// @media (min-width: 481px) and (max-width: 767px) {
// }

// @media (max-width: 480px) {
// }
