.bankInfo {
    .importantInfo {
        font-weight: 600;
        font-size: 1.15rem;
        font-family: 'quicksand', sans-serif;
        margin-bottom: 30px;
    }
    .inputHint {
        text-align: center;
        color: #cc6328;
    }
}

.loaderWrapper {
    margin-top: 40px;
}