.alertsBody {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin: 20px 0;
  border-radius: 5px;
  //   width: 70%;
}

.topBar {
  padding: 10px 20px;
  border-bottom: 1px solid #e2e7eb;
}

.header {
  display: flex;
  align-items: center;
}

.icon {
  width: 80px;
  height: 80px;
}

.header h2 {
  color: #cc6328;
}

.alertsBody {
  max-height: 100vh;
}

.alertBody {
  overflow: auto;
  max-height: 70vh;
}

.alertBody::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.alertBody::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #0004;
  //   visibility: hidden;
}

.alertBody:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.alert {
  padding: 20px;
  margin: 0 0 20px 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.alert p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}
